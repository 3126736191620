import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlTranslated extends MatPaginatorIntl {
  private rangeLabel = '/';

  constructor(private translateService: TranslateService) {
    super();
    this.translateService.onLangChange.subscribe(() => this.getTranslations());
    this.getTranslations();
  }

  getRangeLabel = ((page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.rangeLabel} ${length}`;
    }
    const boundedLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < boundedLength ? Math.min(startIndex + pageSize, boundedLength) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.rangeLabel} ${boundedLength}`;
  });

  getTranslations() {
    this.translateService.get([
      'MATERIAL.PAGINATOR.FIRST_PAGE',
      'MATERIAL.PAGINATOR.LAST_PAGE',
      'MATERIAL.PAGINATOR.ITEMS_PER_PAGE',
      'MATERIAL.PAGINATOR.NEXT_PAGE',
      'MATERIAL.PAGINATOR.PREVIOUS_PAGE',
      'MATERIAL.PAGINATOR.RANGE',
    ])
      .subscribe((translation) => {
        this.firstPageLabel = ''; // disabled since have no interest
        this.lastPageLabel = ''; // disabled since have no interest
        this.itemsPerPageLabel = translation['MATERIAL.PAGINATOR.ITEMS_PER_PAGE'];
        this.nextPageLabel = ''; // disabled since have no interest
        this.previousPageLabel = ''; // disabled since have no interest
        this.rangeLabel = translation['MATERIAL.PAGINATOR.RANGE'];
        this.changes.next();
      });
  }
}
