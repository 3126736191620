import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ProfileService } from '@fairandsmart/angular';
import { FsCguStatus } from '@fairandsmart/types';
import { Observable } from 'rxjs';
import { environment } from '@Env';
import { CguDialogComponent } from '@Common/cgu-dialog/cgu-dialog.component';
import { AuthService } from '@Services/auth.service';
import { CGU } from '@Utils/cgu';

@Injectable({
  providedIn: 'root',
})
export class CguGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService, private dialog: MatDialog, private profileService: ProfileService) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      return this.auth.ready
        .then(() => {
          if (environment.customization.autoAcceptCgu) {
            return this.profileService.updateCgu(CGU.version).toPromise().then(() => true);
          }
          const cguState = this.profileService.checkCguVersion(CGU);
          if (cguState === FsCguStatus.UP_TO_DATE) {
            return true;
          }
          return this.openCguDialog().then((cguAccepted) => {
            if (cguAccepted) {
              return true;
            }
            this.auth.keycloak.logout();
            return false;
          });
        })
        .catch((error: any) => {
          console.error('An unexpected error occurred while checking CGUs', error);
          return false;
        });
    }
    return true;
  }

  private openCguDialog(): Promise<boolean> {
    const dialogRef = this.dialog.open(CguDialogComponent, {
      width: '650px',
      maxWidth: '90vw',
      autoFocus: false,
      disableClose: true,
      data: {
        readOnly: false,
        cgu: CGU,
      },
    });
    return dialogRef.afterClosed().toPromise().then((result: FsCguStatus) => result === FsCguStatus.UP_TO_DATE);
  }
}
