import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'bytes',
})
export class BytesPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(bytes: any, precision: number = 1, locale: string = this.translate.currentLang): string {
    if (bytes == null) {
      return '';
    }
    if (bytes === 0 || Number.isNaN(parseFloat(bytes)) || !Number.isFinite(bytes)) {
      return '-';
    }
    let units;
    const unit = Math.floor(Math.log(bytes) / Math.log(1000));
    const val = formatNumber((bytes / 1000 ** Math.floor(unit)), locale, `1.0-${precision ?? 1}`);
    if (locale === 'fr') {
      units = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po'];
    } else {
      units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    }
    return `${val} ${units[unit]}`;
  }
}
