import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  ApplicationRef, DoBootstrap, LOCALE_ID, NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FsAngularModule } from '@fairandsmart/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import {
  from, Observable, of, throwError,
} from 'rxjs';
import {
  catchError, mergeMap, retry, timeout,
} from 'rxjs/operators';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@Env';
import { FsOrgBaseModule } from '@Common/base.module';
import { LoadingDialogComponent } from '@Common/loading-dialog/loading-dialog.component';
import { NgxStripeModule } from 'ngx-stripe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnectionPagesModule } from './components/connection-pages/connection-pages.module';
import { HomeModule } from './components/home/home.module';
import { KEYCLOAK_CONFIG } from './keycloak-config';
import { DynamicLocaleId } from './dynamicLocaleId';

const keycloakService = new KeycloakService();

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=v0.0.0');
}

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    LoadingDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    ConnectionPagesModule,
    HomeModule,
    FsOrgBaseModule,
    FsAngularModule.forRoot({
      apiUrl: environment.apiUrl,
      wsUrl: environment.wsUrl,
      catalogUrl: environment.catalogUrl,
      hubUrl: environment.hubUrl,
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      positionClass: 'toast-under-nav-bar',
      progressBar: true,
      progressAnimation: 'increasing',
    }),
    ToastContainerModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    // Workaround for https://github.com/angular/angular/issues/15039
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
  ],
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(appRef: ApplicationRef) {
    of(true)
      .pipe(
        catchError((err) => {
          console.error('[WORKER SERVICE] Web Worker are not available in this browserO'
            + '.');
          return throwError(err);
        }),
        mergeMap(() => this.initKeycloak()),
        catchError((err) => {
          console.error('[KEYCLOAK] Keycloak server unreachable. Trying again automatically (timeout 10s).');
          return throwError(err);
        }),
        retry(),
      )
      .subscribe({
        next: () => appRef.bootstrap(AppComponent),
        error: (error) => console.error('[KEYCLOAK] Keycloak initialization failed', error),
      });
  }

  initKeycloak(): Observable<any> {
    return from(keycloakService.init(KEYCLOAK_CONFIG)).pipe(timeout(10000));
  }
}
