import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FsCgu, FsCguStatus } from '@fairandsmart/types';

export interface CguDialogData {
  readOnly?: boolean;
  cgu: FsCgu;
}

@Component({
  selector: 'fs-cgu-dialog',
  templateUrl: './cgu-dialog.component.html',
})
export class CguDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CguDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CguDialogData,
  ) { }

  close(result: FsCguStatus) {
    this.dialogRef.close(result);
  }
}
