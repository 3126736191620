import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileStatus, ProfileType, ProfileVerificationStatus } from '@fairandsmart/types';
import { ProfileService } from '@fairandsmart/angular';
import {
  catchError,
  EMPTY,
  finalize,
  map,
  mergeMap,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@Services/alert.service';
import { ProfileError, ProfileErrorType } from './complete-profile.types';

@Component({
  selector: 'fs-complete-profile-dialog',
  templateUrl: './complete-profile-dialog.component.html',
  styleUrls: ['./complete-profile-dialog.component.scss'],
})
export class CompleteProfileDialogComponent implements OnInit {
  showCodeInput: boolean;

  form = new FormGroup({
    validationCode: new FormControl('', [Validators.required, Validators.pattern(/^\d{6}$/)]),
  });

  readonly VALIDATION_CODE_LENGTH = 6;

  constructor(
    private profile: ProfileService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<CompleteProfileDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.checkProfileStatus().pipe(
      mergeMap(() => this.emailVerificationStatus()),
    ).subscribe({
      next: (shouldEnterEmailCode) => {
        if (shouldEnterEmailCode) {
          this.showCodeInput = true;
        } else {
          this.activateProfile();
        }
      },
      error: (error: ProfileError) => {
        this.alertService.error(error.errorType ? `COMPLETE_PROFILE.PROFILE_ERROR.${error.errorType}` : error.message);
      },
    });
  }

  checkProfileStatus(): Observable<any> {
    if (this.profile.getStatus() === ProfileStatus.ACTIVE && this.profile.getType() === ProfileType.PRO) {
      return of(true);
    }
    if (this.profile.getStatus() === ProfileStatus.ACTIVE && this.profile.getType() !== ProfileType.PRO) {
      return throwError(() => new ProfileError(ProfileErrorType.PERSO));
    }
    if (this.profile.getStatus() === ProfileStatus.BLOCKED) {
      return throwError(() => new ProfileError(ProfileErrorType.BLOCKED));
    }
    if ((this.profile.getStatus() === ProfileStatus.PENDING || this.profile.getStatus() === ProfileStatus.DRAFT)
      && this.profile.getType() !== ProfileType.PRO) {
      return this.profile.updateType(ProfileType.PRO);
    }
    return of(true);
  }

  emailVerificationStatus(): Observable<boolean> {
    if (this.profile.profile.emailVerificationStatus === ProfileVerificationStatus.NOT_VERIFIED) {
      return this.profile.resendActivationCode().pipe(map(() => true));
    }
    return of(false);
  }

  sendCode(): void {
    this.form.markAsPending();
    this.profile.resendActivationCode().pipe(
      catchError((err) => {
        this.alertService.error('CREATE_ORGANISATION.ALERT.ACTIVATION_ERROR', err);
        return EMPTY;
      }),
      finalize(() => {
        this.form.setErrors(null);
      }),
    ).subscribe();
  }

  validateActivationCode(): void {
    this.form.markAsPending();
    const validationCode = this.form.get('validationCode').value;
    this.profile.validateActivationCode(validationCode).pipe(
      catchError((err) => {
        console.error(err);
        this.form.get('validationCode').setErrors({ invalid: true });
        return EMPTY;
      }),
    ).subscribe(() => {
      this.form.setErrors(null);
      this.activateProfile();
    });
  }

  activateProfile() {
    this.profile.updateStatus(ProfileStatus.ACTIVE).subscribe({
      next: () => {
        this.alertService.success('COMPLETE_PROFILE.ACTIVATED');
        this.dialogRef.close();
      },
      error: (err) => {
        console.error(err);
        this.alertService.error('COMPLETE_PROFILE.ERROR');
        this.dialogRef.close();
      },
    });
  }
}
