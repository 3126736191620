import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@Services/auth.service';
import { SecurityService } from '@Services/security.service';

@Injectable({
  providedIn: 'root',
})
export class CustomizationGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router, private security: SecurityService, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(childRoute);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(route);
  }

  check(route: ActivatedRouteSnapshot | Route): boolean {
    if (this.authService.hasRealmRole('fsadmin')) {
      return true;
    }
    let allowed = true;
    const { data } = route;
    if (data?.customization != null) {
      allowed = this.security.checkCustomization(data.customization);
    }
    if (!allowed) {
      if (!this.router.navigated) {
        this.router.navigate(['/']);
      }
    }
    return allowed;
  }
}
