import { Injectable } from '@angular/core';
import { ProfileService } from '@fairandsmart/angular';
import {
  DefaultOrganisationRoleName,
  OrganisationAction,
  OrganisationOffer,
  OrganisationRoleName,
  OrganisationStatus,
  OrganisationType,
  ProfileStatus,
} from '@fairandsmart/types';
import _ from 'lodash';
import { environment } from '@Env';
import { FsOrgCustomization } from '../../environments/fs-org-environment';
import { OrganisationAcl } from '../components/organisation/organisation-acl';
import { OrgaService } from './orga.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(private orgaService: OrgaService, private profile: ProfileService) {
  }

  hasProfileStatus(...statuses: ProfileStatus[]): boolean {
    return statuses.some((s) => s === this.profile.getStatus());
  }

  isUserOrganisationOwner(): boolean {
    return this.profile.getRolesForOrganisation(this.orgaService.id).some((r) => r === DefaultOrganisationRoleName.OWNER);
  }

  isActionAllowedForRoles(action: OrganisationAction, roles: OrganisationRoleName[]): boolean {
    return roles.some((r) => r === DefaultOrganisationRoleName.OWNER)
      || roles.some((roleName) => {
        const role = this.orgaService.roles.find((r) => r.name === roleName);
        if (role == null) {
          throw new Error(`Role "${roleName}" not found for connected organisation`);
        }
        return role.allowed.some((a) => a === action);
      });
  }

  areActionsAllowedForUser(actions: OrganisationAction | OrganisationAction[]): boolean {
    return this.areActionsAllowedForRoles(actions, this.profile.getRolesForOrganisation(this.orgaService.id));
  }

  areActionsAllowedForRoles(actions: OrganisationAction | OrganisationAction[], roles: OrganisationRoleName[]): boolean {
    if (Array.isArray(actions)) {
      return !actions.some((action: OrganisationAction) => !this.isActionAllowedForRoles(action, roles));
    }
    return this.isActionAllowedForRoles(actions, roles);
  }

  isOneActionAllowedForUser(actions: OrganisationAction | OrganisationAction[]): boolean {
    return this.isOneActionAllowedForRoles(actions, this.profile.getRolesForOrganisation(this.orgaService.id));
  }

  isOneActionAllowedForRoles(actions: OrganisationAction | OrganisationAction[], roles: OrganisationRoleName[]): boolean {
    if (roles.some((r) => r === DefaultOrganisationRoleName.OWNER)) {
      return true;
    }
    if (Array.isArray(actions)) {
      return actions.some((action) => this.isActionAllowedForRoles(action, roles));
    }
    return this.isActionAllowedForRoles(actions, roles);
  }

  isOrganisationOfType(types: OrganisationType | OrganisationType[]): boolean {
    return this.orgaService.isOrganisationOfType(types);
  }

  hasOrganisationStatus(statuses: OrganisationStatus | OrganisationStatus[]): boolean {
    return this.orgaService.hasStatus(statuses);
  }

  hasOrganisationOffer(offers: OrganisationOffer | OrganisationOffer[]): boolean {
    return this.orgaService.hasOffer(offers);
  }

  checkAcl(acl: OrganisationAcl): boolean {
    if (acl.statuses != null && !this.hasOrganisationStatus(acl.statuses)) {
      return false;
    }
    if (acl.types != null && !this.isOrganisationOfType(acl.types)) {
      return false;
    }
    if (!this.isOrganisationOfType(OrganisationType.VIRTUAL)) {
      if (acl.offers != null && !this.hasOrganisationOffer(acl.offers)) {
        return false;
      }
    }
    if (acl.actions != null && !this.areActionsAllowedForUser(acl.actions)) {
      return false;
    }
    return true;
  }

  checkCustomization(customization: Partial<FsOrgCustomization>): boolean {
    return !_.some(customization, (expected, key) => environment.customization[key] !== expected);
  }
}
