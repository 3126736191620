import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secToDate',
})
export class SecondsToDatePipe implements PipeTransform {
  // tslint:disable-next-line:ban-types
  transform(seconds: number) {
    const d = new Date(0, 0, 0, 0, 0, 0, 0);
    d.setSeconds(seconds);
    return d;
  }
}
