import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FsAngularComponentsModule } from '@fairandsmart/angular/components';
import { FsAngularDirectivesModule } from '@fairandsmart/angular/directives';
import { RegistryPropertyType } from '@fairandsmart/types';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { FsFormsModule } from '@fairandsmart/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { AceModule } from 'ngx-ace-wrapper';
import { FsOrgPipesModule } from '@Pipes/pipes.module';
import { FsOrgMaterialModule } from '../../../theme/material.module';
import { BaseHeaderComponent } from './base-header/base-header.component';
import { CguDialogComponent } from './cgu-dialog/cgu-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { FairCodeDialogComponent } from './fair-code-dialog/fair-code-dialog.component';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
import { SkeletonTextComponent } from './skeleton-text/skeleton-text.component';
import { EntityIdentifierDisplayComponent } from './entity-identifier-display/entity-identifier-display.component';
import { ConsentManagerDownComponent } from './consent-manager-down/consent-manager-down.component';
import { AceEditorComponent } from './ace-editor/ace-editor.component';
import { DefaultLanguageHelper } from './default-language-helper/default-language-helper';
import { CmEntryAutocompleteComponent } from './cm-entry-autocomplete/cm-entry-autocomplete.component';

@NgModule({
  declarations: [
    BaseHeaderComponent,
    FairCodeDialogComponent,
    LogoutDialogComponent,
    ConfirmDialogComponent,
    ErrorDialogComponent,
    CguDialogComponent,
    SkeletonTextComponent,
    EntityIdentifierDisplayComponent,
    ConsentManagerDownComponent,
    AceEditorComponent,
    DefaultLanguageHelper,
    CmEntryAutocompleteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    NgPipesModule,
    ClipboardModule,
    FsOrgMaterialModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    FsAngularDirectivesModule,
    FsAngularComponentsModule,
    FsFormsModule.forRoot({
      types: [
        { name: RegistryPropertyType.STRING, extends: 'input' },
        { name: RegistryPropertyType.DATE, extends: 'input' },
        { name: RegistryPropertyType.CHOICE, extends: 'select' },
        { name: RegistryPropertyType.MULTIPLE, extends: 'select', defaultOptions: { templateOptions: { multiple: true } } },
      ],
    }),
    FsOrgPipesModule,
    AceModule,
  ],
  exports: [
    BaseHeaderComponent,
    FairCodeDialogComponent,
    LogoutDialogComponent,
    ConfirmDialogComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    NgPipesModule,
    AceModule,
    FsOrgMaterialModule,
    FsAngularDirectivesModule,
    FsAngularComponentsModule,
    CguDialogComponent,
    FsFormsModule,
    SkeletonTextComponent,
    EntityIdentifierDisplayComponent,
    AceEditorComponent,
    DefaultLanguageHelper,
    CmEntryAutocompleteComponent,
    FsOrgPipesModule,
  ],
})
export class FsOrgBaseModule {
}
