import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@Services/auth.service';
import { ErrorDialogComponent } from '@Common/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private auth: AuthService, private dialog: MatDialog) {
  }

  canActivate(route: ActivatedRouteSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      return this.auth.ready
        .then(() => true)
        .catch((error: any) => {
          if (error instanceof HttpErrorResponse && error.status === 0) {
            console.error('[CORE-API] Service appears to be down', error);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                error,
              },
              disableClose: true,
            });
            return false;
          }
          console.error(error);
          return this.redirect(route);
        });
    }
    return this.redirect(route);
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.isAuthenticated() ? true : this.redirect(route);
  }

  private redirect(route: Route | ActivatedRouteSnapshot): boolean {
    if (route.data?.unauthenticatedRedirect) {
      this.router.navigate(route.data.unauthenticatedRedirect);
    } else if (route.data?.keycloakRedirect === 'register') {
      this.auth.keycloak.register();
    } else {
      this.auth.keycloak.login();
    }
    return false;
  }
}
