import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-skeleton-text',
  template: '<p [style.height]="skHeight || \'1em\'" [style.width]="skWidth || \'auto\'"></p>',
  styleUrls: ['./skeleton-text.component.scss'],
})
export class SkeletonTextComponent {
  @Input() skHeight: string;

  @Input() skWidth: string;
}
