import { Component, Inject, OnInit } from '@angular/core';
import {
  catchError, filter, first, mergeMap, tap,
} from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterPreloader } from '@angular/router';
import {
  forkJoin, of, Subject, throwError,
} from 'rxjs';
import { OrgaService } from '@Services/orga.service';
import { AuthService } from '@Services/auth.service';
import { DataExtractorService } from '@Services/data-extractor.service';
import { FlexSearchService } from '../../index/flex-search.service';

enum LoadingComponent {
  INFO = 'INFO',
  WORKSPACE = 'WORKSPACE',
  CPP = 'CPP',
  INDEX = 'INDEX',
}

interface LoaderStep {
  state: 'pending' | 'loading' | 'error' | 'done';
  component: LoadingComponent;
}

@Component({
  selector: 'fs-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
})
export class LoadingDialogComponent implements OnInit {
  progressDeterminate: boolean;

  progress = 0;

  steps: LoaderStep[] = [
    { component: LoadingComponent.WORKSPACE, state: 'pending' },
    { component: LoadingComponent.INFO, state: 'pending' },
    { component: LoadingComponent.CPP, state: 'pending' },
    { component: LoadingComponent.INDEX, state: 'pending' },
  ];

  loaderNotifier$ = new Subject<{ component: LoadingComponent; newState: 'pending' | 'loading' | 'error' | 'done'; }>();

  hasError: boolean;

  constructor(
    private orgaService: OrgaService,
    private authService: AuthService,
    private preloader: RouterPreloader,
    private pdeService: DataExtractorService,
    private flexSearchService: FlexSearchService,
    private dialog: MatDialogRef<LoadingDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: { alias: string },
  ) { }

  ngOnInit(): void {
    this.progressDeterminate = false;
    this.loaderNotifier$.subscribe((stepInfo) => {
      const idx = this.steps.findIndex((s) => s.component === stepInfo.component);
      if (idx > -1) {
        this.steps[idx].state = stepInfo.newState;
      }
    });
    this.load();
  }

  load() {
    /** Order does not matter. Dependencies to another loading component must be handled in the loader */
    forkJoin([
      this.loadCpp(),
      this.loadIndex(),
      this.loadWorkspace(),
      this.loadInfo(),
    ]).subscribe({
      next: () => {
        this.dialog.close(true);
      },
      error: (error) => {
        console.error(error);
        this.hasError = true;
      },
    });
  }

  loadInfo() {
    this.loaderNotifier$.next({ component: LoadingComponent.INFO, newState: 'loading' });
    return this.orgaService.connect(this.data.alias, this.authService.getUserId()).pipe(
      tap((result) => {
        if (result) {
          return this.loaderNotifier$.next({ component: LoadingComponent.INFO, newState: 'done' });
        }
        return of(null);
      }),
      catchError((error) => {
        this.loaderNotifier$.next({ component: LoadingComponent.INFO, newState: 'error' });
        console.error(error);
        return throwError(error);
      }),
    );
  }

  loadWorkspace() {
    this.loaderNotifier$.next({ component: LoadingComponent.WORKSPACE, newState: 'loading' });
    return this.preloader.preload().pipe(
      first(),
      tap(() => {
        this.loaderNotifier$.next({ component: LoadingComponent.WORKSPACE, newState: 'done' });
      }),
      catchError((error) => {
        this.loaderNotifier$.next({ component: LoadingComponent.WORKSPACE, newState: 'error' });
        console.error(error);
        return throwError(error);
      }),
    );
  }

  loadCpp() {
    return this.loaderNotifier$.pipe(
      filter((state) => state.component === LoadingComponent.INFO && state.newState === 'done'),
      first(),
      mergeMap(() => {
        this.loaderNotifier$.next({ component: LoadingComponent.CPP, newState: 'loading' });
        return this.pdeService.init();
      }),
      tap(() => {
        this.loaderNotifier$.next({ component: LoadingComponent.CPP, newState: 'done' });
      }),
      catchError((error) => {
        this.loaderNotifier$.next({ component: LoadingComponent.CPP, newState: 'error' });
        console.error(error);
        return throwError(error);
      }),
    );
  }

  loadIndex() {
    return this.loaderNotifier$.pipe(
      filter((state) => state.component === LoadingComponent.INFO && state.newState === 'done'),
      first(),
      mergeMap(() => {
        this.loaderNotifier$.next({ component: LoadingComponent.INDEX, newState: 'loading' });
        return this.flexSearchService.init();
      }),
      tap(() => {
        this.loaderNotifier$.next({ component: LoadingComponent.INDEX, newState: 'done' });
      }),
      catchError((error) => {
        this.loaderNotifier$.next({ component: LoadingComponent.INDEX, newState: 'error' });
        console.error(error);
        return throwError(error);
      }),
    );
  }
}
