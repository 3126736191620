import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FsInvitationService, FsOrganisationService, ProfileService } from '@fairandsmart/angular';
import {
  EntityCollection,
  EntityIdentifierHelper,
  EntityName,
  Invitation,
  InvitationStatus,
  InvitationType,
  OrganisationStatus,
  OrganisationView,
  OrganisationVisibility,
  ProfileStatus,
  ProfileType,
} from '@fairandsmart/types';
import { of, Subscription } from 'rxjs';
import { debounceTime, map, zipAll } from 'rxjs/operators';
import { environment } from '@Env';
import { AuthService } from '@Services/auth.service';
import { RightConsentsService } from '@Services/right-consents.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@Common/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CompleteProfileDialogComponent } from '../complete-profile-dialog/complete-profile-dialog.component';

@Component({
  selector: 'fs-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  showDetails = false;

  filter: UntypedFormControl;

  readonly CUSTOMIZATION = environment.customization;

  readonly STATUS = OrganisationStatus;

  readonly STATUSES: OrganisationStatus[] = [
    OrganisationStatus.ACTIVE,
    OrganisationStatus.PENDING,
    OrganisationStatus.DRAFT,
    OrganisationStatus.BLOCKED,
  ];

  organisations: OrganisationView[];

  organisationsByStatus: { [status: string]: OrganisationView[] };

  invalidProfileType: boolean;

  invites: Invitation[];

  private subs: Subscription[] = [];

  constructor(
    public profile: ProfileService,
    private auth: AuthService,
    public fsOrganisationService: FsOrganisationService,
    public rightConsentsService: RightConsentsService,
    public fsInvitationService: FsInvitationService,
    private router: Router,
    private dialog: MatDialog,
    private toast: ToastrService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    if ((this.profile.getStatus() === ProfileStatus.ACTIVE || this.profile.getStatus() === ProfileStatus.BLOCKED)
      && this.profile.getType() === ProfileType.PERSO) {
      this.invalidProfileType = true;
      return;
    }
    this.filter = new UntypedFormControl('');
    this.subs.push(
      this.filter.valueChanges.pipe(debounceTime(400)).subscribe((filter: string) => {
        this.filterOrganisations(filter);
      }),
    );
    this.fsOrganisationService.search({
      member: this.profile.id,
      limit: -1,
      visibility: OrganisationVisibility.PRIVATE,
    }).subscribe((response: EntityCollection<OrganisationView>) => {
      this.organisations = response.values;
      this.filterOrganisations(null);
    });
    this.loadInvites();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  loadInvites() {
    of(
      this.fsInvitationService.search({ target: this.profile.reference, status: [InvitationStatus.PENDING], type: InvitationType.ORGANISATION_MEMBER }),
      this.fsInvitationService.search({ target: EntityIdentifierHelper.toEntityReference({ entityId: this.profile.profile.email, entityName: EntityName.RECIPIENT }), status: [InvitationStatus.PENDING], type: InvitationType.ORGANISATION_MEMBER }),
    ).pipe(
      zipAll(),
      map(([res1, res2]) => [...res1.values, ...res2.values].filter((invite) => invite.status === InvitationStatus.PENDING)),
    ).subscribe((result) => {
      this.invites = result;
    });
  }

  private filterOrganisations(filter: string): void {
    const regexp: RegExp | null = filter == null ? null : new RegExp(filter, 'gi');
    this.organisationsByStatus = this.organisations.reduce((previousValue, currentValue) => {
      if (previousValue[currentValue.status] == null) {
        previousValue[currentValue.status] = [];
      }
      if (regexp == null || regexp.test(currentValue.name) || currentValue.id.startsWith(filter)) {
        previousValue[currentValue.status].push(currentValue);
      }
      return previousValue;
    }, {});
  }

  selectOrganisation(organisation: OrganisationView) {
    switch (organisation.status) {
      case OrganisationStatus.BLOCKED:
      case OrganisationStatus.DELETED:
        return;
      case OrganisationStatus.DRAFT:
        this.router.navigate(['/create']);
        return;
      case OrganisationStatus.ACTIVE:
      case OrganisationStatus.PENDING:
        this.router.navigate(['org', organisation.alias]);
        break;
      default:
        this.router.navigate(['/']);
        break;
    }
  }

  acceptInvite(invite: Invitation, name: string) {
    this.router.navigate(['invite'], {
      queryParams: {
        email: btoa(this.profile.profile.email),
        id: invite.id,
        name,
        secret: invite.secret,
        userid: this.profile.id,
      },
    });
  }

  rejectInvite(invite: Invitation) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'HOME.CONFIRM_INVITE_REFUSAL.TITLE',
        content: 'HOME.CONFIRM_INVITE_REFUSAL.CONTENT',
        confirm: 'HOME.CONFIRM_INVITE_REFUSAL.CONFIRM',
        cancel: 'HOME.CONFIRM_INVITE_REFUSAL.CANCEL',
      },
    }).afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        this.fsInvitationService.update(invite.id, { secret: invite.secret, status: InvitationStatus.REJECTED }).subscribe(() => {
          this.toast.info(this.translate.instant('HOME.INVITE_REFUSED'));
          this.loadInvites();
        });
      }
    });
  }

  logout(): void {
    // noinspection JSIgnoredPromiseFromCall
    this.auth.keycloak.logout();
    this.rightConsentsService.purge();
  }

  completeProfile() {
    this.dialog.open(CompleteProfileDialogComponent).afterClosed().subscribe(() => {});
  }
}
