/* @tslint: disable */
import { FsCgu } from '@fairandsmart/types';

export const CGU: FsCgu = {
  version: 2,
  content: `
    <h2 style="text-align: center">CONDITIONS GÉNÉRALES D’UTILISATION</h2>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">PREAMBULE</h3>
    <p>La Société FAIR & SMART, société par actions simplifiée au capital de 85.000 euros, sise 11 Rempart Saint Thiebault, 57000 METZ, RCS METZ 820 924 678 00015 exploite la PLATEFORME FAIR & SMART sur laquelle reposent les SOLUTIONS FAIR & SMART : RIGHT CONSENTS, RIGHT REQUESTS et RIGHT DATA.</p>
    <p style="text-align: center; font-weight: bold;">Important<br>
    Toute utilisation effectuée à quelque titre que ce soit de la PLATEFORME FAIR & SMART implique obligatoirement l'acceptation sans réserve, par L’UTILISATEUR, des présentes Conditions Générales d’Utilisation (CGU).</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 1. DEFINITIONS</h3>
    <p>Dans le cadre des présentes Conditions Générales d’Utilisation, les termes suivants auront la signification donnée ci-dessous :</p>
    <ul>
        <li><strong>« APPLICATION MYFAIRDATA»</strong> : application mobile à laquelle l’UTIILISATEUR FINAL doit se connecter pour utiliser la SOLUTION MYFAIRDATA.</li>
        <li><strong>« COMPTE »</strong> : désigne le compte de l’UTILISATEUR sur la PLATEFORME FAIR & SMART permettant d’accéder aux SOLUTIONS FAIR & SMART. L’accès au COMPTE se fait grâce aux IDENTIFIANTS de l’UTILISATEUR.</li>
        <li><strong>« CLIENT »</strong> : désigne une personne morale ayant souscrit un contrat avec FAIR & SMART permettant à ses UTILISATEURS d’accéder aux SOLUTIONS FAIR & SMART grâce à leur IDENTIFIANT.</li>
        <li><strong>« CONNEXION »</strong> : organisation ou autre UTILISATEUR de la SOLUTION MYFAIRDATA avec laquelle le CLIENT accepte d’être connecté pour interagir, de partager des DONNEES et dont il accepte, le cas échéant, de recevoir des requêtes TRUST. L’entrée en CONNEXION peut nécessiter le partage d’informations personnelles à des fins d’identification mutuelle.</li>
        <li><strong>« CONSENTEMENT »</strong> : manifestation de volonté libre, spécifique, éclairée et univoque donnée à une relation et transmise par FAIR & SMART, par laquelle l’UTILISATEUR FINAL accepte que ses DONNEES soient communiquées à ses CONNEXIONS et/ou utilisées par elles.</li>
        <li><strong>« DONNEES »</strong> : désigne toute information ou document que l’UTILISATEUR ou l’UTILISATEUR FINAL enregistre sur son COMPTE.</li>
        <li><strong>« IDENTIFIANTS »</strong> : désigne les codes confidentiels d’identification et les mots de passe permettant l’accès au COMPTE. Ces Identifiants sont choisis par l’UTILISATEUR. Ils sont à l’usage exclusif de l’UTILISATEUR qui en est seul responsable.</li>
        <li><strong>« PLATEFORME FAIR & SMART »</strong> : application web à laquelle le CLIENT et l’UTILISATEUR peuvent se connecter pour utiliser les SOLUTIONS FAIR & SMART auxquelles il a souscrit.</li>
        <li><strong>« REQUETE TRUST »</strong> : requête reçue d’une CONNEXION pour collecter un consentement ou partager des DONNEES de manière sécurisée via la SOLUTION MYFAIRDATA. Le cas échéant, la requête TRUST a vocation à enregistrer un consentement libre et éclairé au sens de l’article 7 du Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation des données.</li>
        <li><strong>« SOLUTIONS FAIR & SMART »</strong> : désigne les fonctionnalités RIGHT CONSENTS, RIGHT REQUESTS et RIGHT DATA accessibles via la PLATEFORME FAIR & SMART.</li>
        <li><strong>« SOLUTION MYFAIRDATA »</strong> : désigne le service d’intermédiation et de gestion de données à caractère personnel qui permet à l’UTILISATEUR FINAL de stocker, administrer et partager ses DONNEES avec des CONNEXIONS dans le respect du Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation des données.</li>
        <li><strong>UTILISATEUR</strong> : désigne toute personne physique, qui accède et navigue sur les SOLUTIONS FAIR & SMART</li>
        <li><strong>« UTILISATEUR FINAL »</strong> : désigne toute personne physique, qui accède et navigue sur la SOLUTION MYFAIRDATA.</li>
    </ul>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 2. OBJET</h3>
    <p>Les présentes CGU, conclues entre FAIR & SMART d’une part, et l’UTILISATEUR d’autre part, ont pour objet de fixer les dispositions contractuelles relatives aux droits et obligations respectifs des PARTIES dans le cadre de l’utilisation de la PLATEFORME FAIR & SMART et des SOLUTIONS FAIR & SMART.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 3. ACCEPTATION DES CGU</h3>
    <p>L’utilisation des fonctionnalités de la PLATEFORME FAIR & SMART et des SOLUTIONS FAIR & SMART implique l’acceptation des présentes CGU.
    <p>Ainsi, l’UTILISATEUR s’engage à lire attentivement les présentes CGU lors de l’accès à la PLATEFORME FAIR & SMART et est invité à les télécharger, les imprimer et à en conserver une copie.
    <p>Il est précisé que les présentes CGU sont référencées au sein du COMPTE et peuvent ainsi être consultées à tout moment.</p>
    <p>Les présentes CGU s’appliquent à tout UTILISATEUR naviguant sur la PLATEFORME FAIR & SMART.</p>
    <p>FAIR & SMART se réserve la faculté de mettre à jour et de modifier les présentes CGU à tout moment, notamment pour s’adapter à l’évolution législative ou règlementaire. FAIR & SMART en informera par courriel l’ensemble des CLIENTS référencés par un courriel envoyé au moins sept (7) jours avant l’entrée en vigueur des CGU dans leur nouvelle version.</p>
    <p>Les CGU applicables sont celles en vigueur au moment de la navigation de l’UTILISATEUR sur la PLATEFORME FAIR & SMART.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 4.  SPECIFICATIONS TECHNIQUES</h3>
    <p>L’UTILISATEUR peut accéder aux SOLUTIONS FAIR & SMART au moyen d’un navigateur compatible.</p>
    <p>En utilisant les SOLUTIONS FAIR & SMART, l’UTILISATEUR reconnaît disposer des autorisations, moyens et compétences nécessaires à l’utilisation des SOLUTIONS FAIR & SMART.</p>
    <p>Les équipements nécessaires à l’accès et à l’utilisation des SOLUTIONS FAIR & SMART sont à la charge du CLIENT, de même que les frais de télécommunications induits par leur utilisation.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 5. ROLE DE FAIR & SMART</h3>
    <p>FAIR & SMART offre par l’intermédiaire des SOLUTIONS FAIR & SMART, certaines fonctionnalités aux UTILISATEURS.</p>
    <p>A ce titre, FAIR & SMART n’agit ni en leur nom, ni pour leur compte et n’est en conséquence, aucunement impliquée dans les relations qui pourraient naître entre UTILISATEURS et UTILISATEURS FINAUX.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 6. ACCES A L’APPICATION</h3>
    <p>Tout UTILISATEUR souhaitant avoir accès aux fonctionnalités proposées par les SOLUTIONS FAIR & SMART doit être dûment habilité par le CLIENT.</p>
    <p>L’UTILISATEUR doit se connecter à Internet puis ouvrir la PLATEFORME FAIR & SMART depuis un navigateur compatible pour utiliser les SOLUTIONS FAIR & SMART.</p>
    <p>Pour créer un COMPTE, l’UTILISATEUR doit avoir été invité ou indiquer les éléments suivants :</p>
    <ul>
        <li>Adresse email valide</li>
        <li>Nom et prénom</li>
        <li>Mot de passe choisi</li>
    </ul>
    <p>Un courriel de vérification est adressé sur l’adresse communiquée pour activer le COMPTE.</p>
    <p>L’UTILISATEUR doit ensuite définir un 2ème mot de passe (« PASSPHRASE ») pour générer les clés de chiffrement de son COMPTE. Cette PASSPHRASE lui sera demandé à chaque connexion et ne peut pas être restaurée ni remplacée en cas de perte ou d’oubli.</p>
    <p>L’UTILISATEUR est responsable de la conservation de son identifiant, de son mot de passe et de sa Passphrase.</p>
    <p>L’UTILISATEUR s’engage à informer immédiatement FAIR & SMART de toute perte et/ou divulgation de son identifiant, de son mot de passe ou de sa Passphrase.</p>
    <p>Dans certains cas, l’accès aux SOLUTIONS FAIR & SMART peut être réalisé au moyen d’un procédé d’authentification d’identité numérique (de type Token ou autre) qui garantit à l’UTILISATEUR un accès sécurisé à son COMPTE en complément du nom d’utilisateur et du mot de passe choisis au moment de la création du COMPTE.</p>
    <p>Le CLIENT et les UTILISATEURS souhaitant utiliser les SOLUTIONS FAIR & SMART s’engagent à fournir à FAIR & SMART des données exactes, loyales et à jour, qui ne portent pas atteinte, à quelque titre que ce soit, aux droits des tiers et à communiquer à FAIR & SMART toute mise à jour nécessaire des données communiquées lors de la création du COMPTE.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 7. FONCTIONNALITES DE LA PLATEFORME</h3>

    <h4>7.1 Right Consents</h4>
    <p>La PLATEFORME FAIR & SMART propose un outil de gestion des consentements aux traitements de données à caractère personnel : « RIGHT CONSENTS ». Cette offre inclut :</p>
    <ul>
        <li>La gestion des authentifications et identification</li>
        <li>La mise en place d’un registre des consentements</li>
        <li>L’horodatage des consentements</li>
        <li>La possibilité de revenir sur un consentement donné</li>
        <li>Le stockage sécurisé des documents attachés à un consentement</li>
    </ul>
    <p>L’outil de gestion des consentements « RIGHT CONSENTS » proposé par FAIR & SMART est basé sur la mise en place de modèles pouvant ensuite être utilisés via un formulaire de collecte intégrable dans la plupart des applications ou sites web existants (iframe, redirection web, impression papier).</p>
    <p>Le modèle de recueil des consentements est entièrement personnalisable par le CLIENT. L’intégralité des informations liées à un modèle et les styles de présentations sont paramétrables via une interface web de mise en place des modèles (Back Office).</p>
    <p>Le CLIENT reste seul responsable du respect des règles de collecte des données à caractère personnel et des règles de consentement dans les formulaires intégrés dans le module de Gestion des consentements Fair & Smart.</p>

    <h4>7.2 Rights Requests</h4>
    <p>La PLATEFORME FAIR & SMART propose un outil de gestion de requêtes RGPD : « RIGHT REQUESTS ». Il permet au CLIENT de centraliser les requêtes en exercice de droit qui leur sont adressées par des particuliers dans le cadre du RGPD et d’en suivre le bon traitement, dans les délais impartis, avec la génération et l’archivage des éléments nécessaires. Cette offre inclut :</p>
    <ul>
        <li>la gestion des authentifications et identifications</li>
        <li>la mise en place d’un backoffice de gestion des requêtes</li>
        <li>l’horodatage de tous les messages échangées autour d’une requête</li>
        <li>le suivi des requêtes ‘en cours’ et ‘archivées’</li>
        <li>le stockage sécurisé et géolocalisé des documents attachés à une requête</li>
        <li>l’intégration de documents externes numérisés</li>
        <li>le paramétrage des rôles et des actions autorisées pour chaque utilisateur</li>
        <li>la mise à disposition d’espaces sécurisés d’échange de fichiers pour les pièces trop volumineuses ou contenant des données sensibles</li>
        <li>le suivi de toutes les actions effectuées autour d’une requête.</li>
    </ul>
    <p>L’outil de gestion des requêtes RGPD « RIGHTS REQUESTS » proposé par FAIR & SMART permet de gérer tous les types de requêtes RGPD (accès, portabilité, effacement, opposition, limitation, rectification). Il propose des modèles de réponse entièrement personnalisables par le CLIENT (Back Office).</p>
    <p>Le CLIENT reste seul responsable de ses choix de modalité de réponse et des données à caractère personnel qu’il peut être amené à restituer (nature, format, exhaustivité…) dans le cadre de son utilisation du module de Gestion des requêtes RGPD FAIR & SMART.</p>

    <h4>7.3 Right Data</h4>
    <p>La PLATEFORME FAIR & SMART propose un outil de partage et de transfert de données personnelles : « RIGHT DATA ».</p>
    <p>La SOLUTION MYFAIRDATA permet aux UTILISATEURS FINAUX de partager des DONNEES avec des CONNEXIONS soit de manière proactive, soit sur la base d’une sollicitation préalable (REQUETE TRUST) dans le respect des autorisations consenties par les UTILISATEURS FINAUX.</p>
    <p>La PLATEFORME FAIR & SMART offre ainsi un accès aux DONNEES partagées par les UTILISATEURS FINAUX et permet leur transfert aux CONNEXIONS enregistrées conformément au CONSENTEMENT donné par les UTILISATEURS FINAUX et de manière sécurisée.</p>
    <p>FAIR & SMART transmet aux UTILISATEURS FINAUX les requêtes TRUST adressés par les UTILISATEURS. Les UTILISATEURS FINAUX sont libres d’accepter ou de refuser la mise en relation.</p>
    <p>Les DONNEES des UTILISATEURS FINAUX accessibles depuis la PLATEFORME FAIR & SMART appartiennent aux UTILISATEURS FINAUX et eux seuls déterminent quelle CONNEXION peut avoir accès à quelles DONNEES selon les termes exposés dans la requête TRUST.</p>
    <p>Lorsqu’il sert de base légale, le CONSENTEMENT est enregistré pour chaque requête TRUST. Chaque REQUETE TRUST est spécifique ; le CONSENTEMENT est différent d’une CONNEXION à l’autre et peut être modifié ou révoqué à tout moment par l’UTILISATEUR FINAL.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 8. OBLIGATIONS DU CLIENT ET DE L’UTILISATEUR</h3>
    <p>Dans le cadre de l'utilisation des SOLUTIONS FAIR & SMART, chaque UTILISATEUR ou CLIENT s'engage à ne pas porter atteinte à l’ordre public et à se conformer aux lois et règlements en vigueur, à respecter les droits des tiers et des autres UTILISATEURS et CLIENTS et les dispositions des présentes CONDITIONS GENERALES D’UTILISATION.</p>
    <p>Chaque UTILISATEUR ou CLIENT est seul responsable de l’ensemble des informations, DONNEES et correspondances qu’il met en ligne ou communique par l’intermédiaire des SOLUTIONS FAIR & SMART.</p>
    <p>Chaque UTILISATEUR ou CLIENT a pour obligation de :</p>
    <ul>
        <li>Se comporter de façon loyale et raisonnable à l’égard de FAIR & SMART, des tiers, des UTILISATEURS et des CLIENTS ;</li>
        <li>Etre honnête et sincère dans les informations fournies à FAIR & SMART et, le cas échéant aux tiers, incluant l’ensemble des autres UTILISATEURS et CLIENTS ;</li>
        <li>Utiliser les SOLUTIONS FAIR & SMART conformément aux finalités décrites dans les présentes CGU ;</li>
        <li>Ne pas tenter de commettre un quelconque acte de contrefaçon à l’égard de tiers, et ce par quelque moyen que ce soit ;</li>
        <li>Ne pas détourner la finalité des SOLUTIONS FAIR & SMART pour commettre des crimes, délits ou contraventions réprimés par le Code pénal ou toute autre loi ;</li>
        <li>Ne pas tenter de détourner les internautes vers un autre site ou un service concurrent ;</li>
        <li>Ne pas chercher à porter atteinte au sens des articles 323-1 et suivants du Code pénal aux systèmes de traitement automatisés de données mis en œuvre pour la mise en ligne des SOLUTIONS FAIR & SMART;</li>
        <li>Ne pas publier d’information, message ou tout autre contenu contraire aux présentes CGU, aux lois, règlements en vigueur ou aux bonnes mœurs, et en particulier des contenus contrefaisants, ou bien encore des textes diffamatoires ou injurieux ou qui seraient constitutifs de discrimination, d’incitation à la haine ou à la violence, d’atteinte à la vie privée, de mise en péril des mineurs ;</li>
        <li>Ne pas usurper l’identité ou le statut d’un tiers, d’un autre UTILISATEUR ou d’un autre CLIENT de la PLATEFORME ou des SOLUTIONS FAIR & SMART ;</li>
        <li>Ne pas mentionner de donnée relative à des infractions, condamnations, mesures de sureté concernant sa personne ou celle d’un tiers ;</li>
        <li>Ne pas commettre, par l’intermédiaire des SOLUTIONS FAIR & SMART, d’actes pouvant être analysés comme des actes de concurrence déloyale, de parasitisme, ou de toute autre infraction allant à l’encontre d’une bonne et saine concurrence ;</li>
        <li>Ne pas revendre ou céder, de quelque manière que ce soit, à qui que ce soit, les données d’un UTILISATEUR, qu’il aurait acquis sur la PLATEFORME FAIR & SMART ;</li>
        <li>Utiliser l’outil de messagerie raisonnablement, de manière licite, cordiale et bienveillante, en respectant les tiers et autres UTILISATEURS et CLIENTS de la PLATEFORME FAIR & SMART.</li>
    </ul>
    <p>Tout manquement, imputable à un CLIENT ou à un UTILISATEUR, d’une des obligations susmentionnées, ou à l’une des dispositions des présentes CGU justifiera la suspension ou, le cas échéant, la suppression de son accès à la PLATEFORME sans mise en demeure préalable, et sans versement d’indemnité d’aucune sorte.</p>
    <p>En tout état de cause, l’UTILISATEUR ou le CLIENT garantit FAIR & SMART contre tout recours intenté à son encontre, ou de toute condamnation mise à sa charge, du fait du manquement de l’UTILISATEUR ou du CLIENT à ses obligations.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 9. RESPONSABILITE DE FAIR & SMART</h3>
    <p>Dans le cadre de la mise à disposition des SOLUTIONS FAIR & SMART, FAIR & SMART ne sera tenue qu’à une obligation de moyens. Il ne pèse sur FAIR & SMART aucune obligation de résultat ou de moyens renforcés d’aucune sorte.</p>
    <p>Les modalités d’engagement de la responsabilité de FAIR & SMART sont détaillées au sein des Conditions Générales de Services conclues avec le CLIENT.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 10. PROTECTION DES DONNEES A CARACTERE PERSONNEL</h3>
    <p>FAIR & SMART collecte des données à caractère personnel concernant les UTILISATEURS en vue de les faire bénéficier de l’ensemble des services et fonctionnalités des SOLUTIONS FAIR & SMART.</p>
    <p>A ce titre, les UTILISATEURS sont renvoyés à la Politique de Confidentialité FAIR & SMART accessible au lien suivant : <a href="https://www.fairandsmart.com/politique-confidentialite/" target="_blank">https://www.fairandsmart.com/politique-confidentialite/</a></p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 11. PROPRIETE INTELLECTUELLE</h3>
    <p>L’UTILISATEUR ou le CLIENT reconnaît les droits de propriété intellectuelle de FAIR & SMART sur l’APPLICATION MYFAIRDATA, les SOLUTIONS FAIR & SMART et la PLATEFORME FAIR & SMART, ses composantes et les CONTENUS y afférant et renonce à contester ces droits sous quelque forme que ce soit.</p>
    <p>Les marques, logos, slogans, graphismes, photographies, animations, vidéos, solutions logicielles et textes contenus sur l’APPLICATION MYFAIRDATA, les SOLUTIONS FAIR & SMART ou la PLATEFORME FAIR & SMART, <strong>à l’exception des DONNEES générés et publiés par les UTILISATEURS</strong>, appartiennent à FAIR & SMART et ne peuvent en conséquence être reproduits, utilisés ou représentés sans autorisation expresse préalable sous peine de poursuites judiciaires.</p>
    <p>Toute représentation ou reproduction, totale ou partielle, des SOLUTIONS FAIR & SMART, des données qui y sont contenues, et des DONNEES qui y sont accessibles, par quelque procédé que ce soit, sans l’autorisation préalable expresse préalable de FAIR & SMART, est interdite et pourra donner lieu à des poursuites judiciaires.</p>
    <p>En particulier, en dehors des cas autorisés par FAIR & SMART dans le cadre de les présentes CGU, FAIR & SMART interdit expressément :</p>
    <ul>
        <li>L’extraction, par transfert permanent ou temporaire de la totalité ou d’une partie qualitativement ou quantitativement substantielle du contenu d’une base de données sur un autre support, par tout moyen et sous toute forme que ce soit ;</li>
        <li>L’extraction ou la réutilisation répétée et systématique de parties qualitativement ou quantitativement non substantielles du contenu de la base de données des SOLUTIONS FAIR & SMART ou de la PLATEFORME FAIR & SMART;</li>
        <li>La réutilisation, par la mise à la disposition du public de la totalité ou d’une partie qualitativement ou quantitativement substantielle du contenu de la base, quelle qu’en soit la forme.</li>
    </ul>
    <p>L’acceptation des présentes CGU vaut reconnaissance par les UTILISATEURS et les CLIENTS des droits de propriété intellectuelle de FAIR & SMART et l’engagement de les respecter.</p>
    <p>FAIR & SMART accorde une licence non-exclusive et non cessible aux UTILISATEURS et aux CLIENTS les autorisant à utiliser les SOLUTIONS FAIR & SMART conformément aux présentes CGU.</p>
    <p>Toute autre exploitation des SOLUTIONS FAIR & SMART et de leur contenu est exclue du domaine de la présente licence et ne pourra être effectuée sans l’autorisation préalable expresse de FAIR & SMART.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 12. FORCE MAJEURE</h3>
    <p>FAIR & SMART ne pourra être tenue pour responsable, ou considérée comme ayant failli aux présentes CGU, pour tout retard ou inexécution, lorsque la cause du retard ou de l'inexécution est liée à un cas de force majeure telle qu'elle est définie par la jurisprudence des cours et tribunaux français y compris notamment en cas d'attaque de pirates informatiques, d'indisponibilité de matériels, fournitures, pièces détachées, équipements personnels ou autres ; et d'interruption, de suspension, de réduction ou des dérangements de l'électricité ou autres ou toutes interruptions de réseaux de communications électroniques, ainsi qu’en cas de survenance de toute circonstance ou événement extérieur à la volonté de FAIR & SMART intervenant postérieurement à la conclusion des CGU et en empêchant l’exécution dans des conditions normales.</p>
    <p>Il est précisé que, dans une telle situation, l’UTILISATEUR ou le CLIENT ne peut réclamer le versement d’aucune indemnité et ne peut intenter aucun recours à l’encontre de FAIR & SMART.</p>
    <p>En cas de survenance d’un des évènements susvisés, FAIR & SMART s’efforcera d’informer l’UTILISATEUR ou le CLIENT dès que possible.</p>

    <h3 style="background-color: #41BCD7; color: #FFF; padding: 3px">ARTICLE 13. DISPOSITIONS GENERALES</h3>
    <p>Si l’une quelconque des stipulations des présentes CGU venait à être déclarée nulle au regard d’une disposition législative ou réglementaire en vigueur et/ou d’une décision de justice ayant autorité de la chose jugée, elle sera réputée non écrite mais n’affectera en rien la validité des autres clauses qui demeureront pleinement applicables.</p>
    <p>Une telle modification ou décision n’autorise en aucun cas les UTILISATEURS et les CLIENTS à méconnaitre les présentes CGU.</p>
    <p>Le fait que l'une des PARTIES n'ait pas exigé l'application d'une clause quelconque des présentes CGU, que ce soit de façon permanente ou temporaire, ne pourra en aucun cas être considéré comme une renonciation à ladite clause.</p>
    <p>En cas de difficulté d’interprétation entre l’un quelconque des titres figurant en tête des clauses, et l’une quelconque de celles-ci, les titres seront déclarés inexistants.</p><br>

    <small>  © FAIR & SMART - Tous droits réservés 2019<br>  FAIR & SMART, société par actions simplifiée sise 11 Rempart Saint Thiebault, 57000 METZ<br>  RCS METZ 820 924 678 00015<br></small>`,
};
