import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  listEntries,
  ModelDataType,
  ModelEntryDto,
  ModelEntryStatus,
  ModelFilter,
  ModelVersionStatus,
} from '@fairandsmart/consents/models';
import { map, startWith } from 'rxjs/operators';
import {
  ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Peer } from '@fairandsmart/consents-ce/peers';
import { CollectionPage } from '@fairandsmart/consents';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'fs-cm-entry-autocomplete',
  templateUrl: './cm-entry-autocomplete.component.html',
  styleUrls: ['./cm-entry-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CmEntryAutocompleteComponent,
    },
  ],
})
export class CmEntryAutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges {
  @ViewChild('inputElement') inputElement: ElementRef;

  @Input() language: string;

  @Input() ignoreLang: boolean;

  @Input() entryTypes: ModelDataType[];

  @Input() noEntryMessage: string = 'RIGHT_CONSENTS.RECORDS.OPERATOR.DEFAULT.NONE';

  @Input() label: string;

  @Input() required: boolean = false;

  @Input() disabled: boolean = false;

  @Input() appearance: MatFormFieldAppearance = 'fill';

  @Input() peer: Peer;

  public filteredEntries$: Observable<ModelEntryDto[]>;

  entries: ModelEntryDto[];

  autoCompleteInput = new UntypedFormControl(undefined);

  onChange;

  onTouched;

  @Output() modelSelected = new EventEmitter<ModelEntryDto>();

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    if (this.required) {
      this.autoCompleteInput.setValidators(Validators.required);
    }
    this.filteredEntries$ = this.autoCompleteInput.valueChanges.pipe(
      startWith(''),
      map((value) => {
        if (this.entries) {
          if (typeof value === 'string' && value?.length) {
            const lowercaseValue = value.toLocaleLowerCase();
            return this.entries.filter((e) => e.key.indexOf(lowercaseValue) > -1 || e.name.toLocaleLowerCase().indexOf(lowercaseValue) > -1);
          }
          return this.entries;
        }
        return [];
      }),
    );
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  getName(entry: ModelEntryDto) {
    return entry?.name;
  }

  reset() {
    this.autoCompleteInput.setValue(null);
    this.inputElement.nativeElement.blur();
  }

  public refresh() {
    if (this.disabled || (!this.ignoreLang && (this.language === undefined || this.language === null || this.language === ''))) {
      this.autoCompleteInput.disable();
    } else {
      this.autoCompleteInput.enable();
    }
    const filter: ModelFilter = {
      languages: this.ignoreLang ? [] : [this.language],
      types: this.entryTypes,
      size: -1,
      statuses: [ModelEntryStatus.ACTIVE],
    };
    if (this.peer === undefined) {
      listEntries(filter).subscribe((result) => {
        this.entries = result.values;
        if (this.autoCompleteInput.value && this.entries.filter((e) => e.key === this.autoCompleteInput.value.key).length === 0) {
          this.autoCompleteInput.setValue(undefined);
        }
        this.autoCompleteInput.updateValueAndValidity();
      });
    } else {
      filter.shared = [true];
      this.http.get<CollectionPage<ModelEntryDto>>(`${this.peer.url}/models`, {
        params: new HttpParams({ fromObject: filter as any }),
        headers: { Authorization: `Basic ${this.peer.apiKey}` },
      }).subscribe((result) => {
        this.entries = result.values;
        if (this.autoCompleteInput.value && this.entries.filter((e) => e.key === this.autoCompleteInput.value.key).length === 0) {
          this.autoCompleteInput.setValue(undefined);
        }
        this.autoCompleteInput.updateValueAndValidity();
      });
    }
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.modelSelected.emit(event.option.value as ModelEntryDto);
    if (this.onChange) {
      this.onChange(event.option.value);
    }
  }

  hasDraft(entry: ModelEntryDto) {
    return !!(entry?.versions?.find((v) => v.status === ModelVersionStatus.DRAFT));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    this.autoCompleteInput.setValue(obj);
  }
}
