<img class="logo" alt="logo" [style]="CUSTOMIZATION.splashLogoStyle" [src]="CUSTOMIZATION.splashLogoUrl">
<ng-container *ngIf="error == null; else errorCard">
  <div class="login-card">
    <div class="card-block">
      <div [ngSwitch]="step">
        <ng-container *ngSwitchCase="'acceptInvite'">
          <h1>{{'MEMBERSHIP_INVITATION.INVITATION.TITLE' | translate}}</h1>
          <p class="fade-text">
            {{'MEMBERSHIP_INVITATION.INVITATION.DESCRIPTION' | translate}}
            <span class="organisation-name">{{organisationName}}</span>
          </p>
          <button (click)="acceptInvite()" mat-flat-button color="primary">
            {{'MEMBERSHIP_INVITATION.BUTTONS.ACCEPT' | translate}}
          </button>
        </ng-container>
        <div *ngSwitchCase="'completeProfile'" class="complete-profile">
          <h4>{{'MEMBERSHIP_INVITATION.COMPLETE_PROFILE.TITLE' | translate:{appName: CUSTOMIZATION.appName} }}</h4>
          <p>{{'MEMBERSHIP_INVITATION.COMPLETE_PROFILE.DESCRIPTION' | translate}}</p>
          <form [formGroup]="completeProfileForm" (ngSubmit)="completeProfile()">
            <mat-form-field [appearance]="'fill'">
              <mat-label>{{'MEMBERSHIP_INVITATION.COMPLETE_PROFILE.USERNAME' | translate}}</mat-label>
              <input matInput formControlName="username" [minlength]="USERNAME_MIN_LENGTH"
                     [maxlength]="USERNAME_MAX_LENGTH" type="text" autocomplete="off" required/>
              <mat-error *ngIf="completeProfileForm.hasError('minlength', ['username'])">
                {{'COMMON.ERRORS.MIN_LENGTH' | translate:{minLength: USERNAME_MIN_LENGTH} }}
              </mat-error>
              <mat-error *ngIf="completeProfileForm.hasError('maxlength', ['username'])">
                {{'COMMON.ERRORS.MAX_LENGTH' | translate:{maxLength: USERNAME_MAX_LENGTH} }}
              </mat-error>
              <mat-error *ngIf="completeProfileForm.hasError('required', ['username'])">
                {{'COMMON.ERRORS.REQUIRED' | translate}}
              </mat-error>
            </mat-form-field>
            <button mat-flat-button type="submit" color="primary">
              {{"COMMON.BUTTONS.SUBMIT" | translate}}
            </button>
          </form>
        </div>
        <div *ngSwitchCase="'joinSuccessful'">
          <div class="successful-join">
            <mat-icon>check_circle</mat-icon>
            <span
              [innerHtml]="'MEMBERSHIP_INVITATION.SUCCESS.DESCRIPTION' | translate:{organisationName: organisation.name}"></span>
          </div>
          <div class="successful-join-next">
            <button (click)="goToOrganisation()" mat-flat-button color="primary">
              {{'COMMON.BUTTONS.CONTINUE' | translate}}
            </button>
          </div>
        </div>
        <ng-container *ngSwitchCase="'joinError'">
          <p class="mat-text-warn">{{joinError.localizedMessage}}</p>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <mat-spinner [diameter]="50"></mat-spinner>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #errorCard>
  <div class="login-card">
    <div class="card-block error">
      <p [innerHtml]="'MEMBERSHIP_INVITATION.ERRORS.' + error.code | translate"></p>
      <button mat-flat-button color="primary" (click)="logout()"
              *ngIf="error.code === 'EMAIL_MISMATCH' || error.code === 'PROFILE_TYPE'">
        {{'COMMON.BUTTONS.LOGOUT' | translate}}
      </button>
    </div>
  </div>
</ng-template>
