<div class="loading">
  <h1>{{'LOADING_DIALOG.TITLE' | translate}}</h1>
  <span>{{'LOADING_DIALOG.SUBTITLE' | translate}}</span>

  <ul class="loaders">
    <li *ngFor="let step of steps" [ngClass]="step.state">
      <ng-container *ngIf="!hasError">
        <ng-container [ngSwitch]="step.state">
          <mat-icon *ngSwitchCase="'pending'">schedule_outline</mat-icon>
          <mat-spinner *ngSwitchCase="'loading'" [diameter]="19"></mat-spinner>
          <mat-icon *ngSwitchCase="'error'">error_outline</mat-icon>
          <mat-icon *ngSwitchCase="'done'">check_circle_outline</mat-icon>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="hasError">
        <ng-container [ngSwitch]="step.state">
          <mat-icon *ngSwitchCase="'pending'">block_outline</mat-icon>
          <mat-icon *ngSwitchCase="'loading'">block_outline</mat-icon>
          <mat-icon *ngSwitchCase="'error'">error_outline</mat-icon>
          <mat-icon *ngSwitchCase="'done'">check_circle_outline</mat-icon>
        </ng-container>
      </ng-container>

      <span>{{('LOADING_DIALOG.' + step.component + '.' + step.state) | translate}}</span>
    </li>
  </ul>

  <div class="error-info" *ngIf="hasError">
    <strong>{{'LOADING_DIALOG.ERROR_TITLE' | translate}}</strong>
    <p>{{'LOADING_DIALOG.ERROR_MESSAGE' | translate}}</p>
    <p>{{'LOADING_DIALOG.ERROR_PERSIST' | translate}}</p>
    <div class="actions">
      <button mat-flat-button color="primary" [matDialogClose]="false">{{'COMMON.BUTTONS.CLOSE' | translate}}</button>
    </div>
  </div>
</div>
