import { AbstractEntity } from '@fairandsmart/types';
import * as _ from 'lodash';
import { NEVER, Observable, Subject } from 'rxjs';

export class IndexingQueue {
  private queue: AbstractEntity[] = [];

  private highPriorityQueue: AbstractEntity[] = [];

  private tasks = new Subject<AbstractEntity>();

  private locked = false;

  observe(): Observable<AbstractEntity> {
    if (this.tasks.observers.length === 1) {
      console.error('Cannot have multiple observers');
      return NEVER;
    }
    return this.tasks;
  }

  isEmpty(): boolean {
    return this.queue.length === 0 && this.highPriorityQueue.length === 0;
  }

  lock(): void {
    this.locked = true;
  }

  unlock(): void {
    this.locked = false;
  }

  isLocked(): boolean {
    return this.locked;
  }

  taskDone(): void {
    this.unlock();
  }

  processNext(): void {
    window.setTimeout(() => {
      if (!this.isObserved()) {
        console.warn('No observer');
        return;
      }
      if (this.locked) {
        return;
      }
      const next = this.getNext();
      if (next != null) {
        this.locked = true;
        this.tasks.next(next);
      } else {
        this.locked = false;
      }
    });
  }

  push(entities: AbstractEntity[], highPriority: boolean): void {
    if (highPriority) {
      this.pushHigh(entities);
    } else {
      this.pushLow(entities);
    }
  }

  private pushLow(entities: AbstractEntity[]): void {
    _.pullAllWith(entities, this.highPriorityQueue, (a, b) => a.entityId === b.entityId);
    this.queue = _.unionWith(this.queue, entities, (a, b) => a.entityId === b.entityId);
    this.processNext();
  }

  private pushHigh(entities: AbstractEntity[]): void {
    _.pullAllWith(this.queue, entities, (a, b) => a.entityId === b.entityId);
    _.pullAllWith(this.highPriorityQueue, entities, (a, b) => a.entityId === b.entityId);
    this.highPriorityQueue.unshift(...entities);
    this.processNext();
  }

  private getNext(): AbstractEntity | null {
    if (this.highPriorityQueue.length > 0) {
      return this.highPriorityQueue.shift();
    }
    if (this.queue.length > 0) {
      return this.queue.shift();
    }
    return null;
  }

  private isObserved(): boolean {
    return this.tasks.observers.length > 0;
  }
}
