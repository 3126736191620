import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@Services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      if (route.queryParamMap.has('from')) {
        return this.router.parseUrl(route.queryParamMap.get('from'));
      }
      return this.router.createUrlTree(['/home']);
    }
    return true;
  }
}
