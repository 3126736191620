import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import {
  OrganisationAction,
  OrganisationOffer,
  OrganisationStatus,
  OrganisationType,
  ProfileStatus,
} from '@fairandsmart/types';
import { AuthenticatedGuard } from '@Guards/authenticated.guard';
import { CguGuard } from '@Guards/cgu.guard';
import { CustomizationGuard } from '@Guards/customization.guard';
import { UnauthenticatedGuard } from '@Guards/unauthenticated.guard';
import { ConfirmDialogData } from '@Common/confirm-dialog/confirm-dialog.component';
import { ORG_PREFIX } from '@Common/constants';
import {
  MembershipInvitationComponent,
} from './components/connection-pages/membership-invitation/membership-invitation.component';
import { WelcomePageComponent } from './components/connection-pages/welcome-page/welcome-page.component';
import { HomePageComponent } from './components/home/home-page/home-page.component';
import { CustomPreloadingStrategy } from './custom-preloading-strategy.service';
import { FsOrgCustomization } from '../environments/fs-org-environment';

export interface FsOrgRouteData {
  profileStatus?: ProfileStatus[];
  statuses?: OrganisationStatus[];
  offers?: OrganisationOffer[];
  types?: OrganisationType[];
  actions?: OrganisationAction[];
  helpLabels?: string[];
  preload?: 'acl' | 'authenticated' | 'always';
  unauthenticatedRedirect?: string[];
  keycloakRedirect?: string;
  customization?: Partial<FsOrgCustomization>;
  prompt?: ConfirmDialogData;
  enforceConsentManagerAvailability?: boolean;
}

export interface FsOrgRoute extends Route {
  data?: FsOrgRouteData;
  children?: FsOrgRoutes;
}

export type FsOrgRoutes = FsOrgRoute[];

export const ROUTES: FsOrgRoutes = [
  {
    path: '',
    component: WelcomePageComponent,
    canActivate: [UnauthenticatedGuard],
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthenticatedGuard, CguGuard],
    data: {
      unauthenticatedRedirect: ['/'],
    },
  },
  {
    path: 'create',
    loadChildren: () => import('./components/organisation/creation/organisation-creation.module').then((m) => m.OrganisationCreationModule),
    canLoad: [CustomizationGuard, AuthenticatedGuard],
    canActivate: [CguGuard],
    data: {
      keycloakRedirect: 'register',
      customization: {
        allowOrganisationCreation: true,
      },
    },
  },
  {
    path: 'invite',
    component: MembershipInvitationComponent,
    canActivate: [CguGuard],
  },
  {
    path: ORG_PREFIX,
    loadChildren: () => import('./components/organisation/organisation.module').then((m) => m.OrganisationModule),
    canActivate: [AuthenticatedGuard, CguGuard],
    data: {
      preload: 'authenticated',
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { preloadingStrategy: CustomPreloadingStrategy })],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy],
})
export class AppRoutingModule {
}
