import { Pipe, PipeTransform } from '@angular/core';
import { RegistryService } from '@fairandsmart/angular';
import { FairQueryHelper } from '@fairandsmart/types';

@Pipe({
  name: 'humanReadableQuery',
  pure: false,
})
export class HumanReadableQueryPipe implements PipeTransform {
  constructor(private registryService: RegistryService) {
  }

  public transform(value: string, lang: string = this.registryService.getRegistry().currentLang) {
    if (!value) {
      return '';
    }
    const clazzId = FairQueryHelper.getClassFromExp(value);
    const propId = FairQueryHelper.getPropertyFromExp(value);
    let out = this.registryService.getRegistry().getLabel(clazzId, lang);
    if (propId) {
      out += ` > ${this.registryService.getRegistry().getLabel(propId, lang)}`;
    }
    return out;
  }
}
