<mat-spinner diameter="21" *ngIf="!showCodeInput"></mat-spinner>

<ng-container *ngIf="showCodeInput">
  <div class="form">
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{'CREATE_ORGANISATION.STEP_EMAIL_VALIDATION.CODE.LABEL' | translate}}</mat-label>
        <input matInput formControlName="validationCode" required [minlength]="VALIDATION_CODE_LENGTH"
               [maxlength]="VALIDATION_CODE_LENGTH" autocomplete="off" type="tel"/>
        <mat-hint>{{'CREATE_ORGANISATION.STEP_EMAIL_VALIDATION.CODE.HINT' | translate}}</mat-hint>
        <mat-error *ngIf="form.hasError('invalid', ['validationCode'])">
          {{'CREATE_ORGANISATION.STEP_EMAIL_VALIDATION.CODE.ERROR_INVALID' | translate}}
        </mat-error>
      </mat-form-field>
    </form>
    <div class="wizard-footer">
      <button mat-flat-button color="primary" [disabled]="form.pending" (click)="validateActivationCode()">
        {{'COMMON.BUTTONS.SUBMIT' | translate}}
      </button>
      <button mat-flat-button color="default" [disabled]="form.pending" (click)="sendCode()">
        {{'CREATE_ORGANISATION.STEP_EMAIL_VALIDATION.BUTTONS.ASK_NEW_CODE' | translate}}
      </button>
    </div>
  </div>
</ng-container>
