export enum ProfileErrorType {
  PERSO = 'PERSO',
  BLOCKED = 'BLOCKED',
  UNKNOWN = 'UNKNOWN',
}

export class ProfileError extends Error {
  constructor(public errorType: ProfileErrorType) {
    super();
  }
}
