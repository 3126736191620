import { Component } from '@angular/core';

@Component({
  selector: 'fs-logout-dialog',
  template: `
    <h2 mat-dialog-title>{{'LOGOUT.TITLE' | translate}}</h2>
    <mat-dialog-content>{{'LOGOUT.CONFIRMATION' | translate}}</mat-dialog-content>
    <mat-dialog-actions class="btn-uppercase align-right">
      <button mat-button [matDialogClose]="false" color="primary">{{'COMMON.BUTTONS.CANCEL' | translate}}</button>
      <button mat-button [matDialogClose]="true" color="primary">{{'COMMON.BUTTONS.CONFIRM' | translate}}</button>
    </mat-dialog-actions>
  `,
})
export class LogoutDialogComponent {
}
