import {
  AfterViewInit, ChangeDetectorRef, Component, ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/fr';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { FsConfig } from '@fairandsmart/angular';
import { environment } from '@Env';
import { LANGUAGES } from '@Common/constants';
import { FsOrgCustomization } from '../environments/fs-org-environment';
import { BackgroundService } from './workers/worker.service';

@Component({
  selector: 'fs-org',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild(ToastContainerDirective)
    toastContainer: ToastContainerDirective;

  readonly CUSTOMIZATION: FsOrgCustomization = environment.customization;

  constructor(
    private translate: TranslateService,
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private titleService: Title,
    private worker: BackgroundService,
    private config: FsConfig,
  ) {
    this.worker.init();
    this.translate.addLangs(LANGUAGES);
    const browserLang = this.translate.getBrowserLang();
    const lang = this.translate.langs.includes(browserLang) ? browserLang : 'fr';
    this.translate.use(lang);
    moment.locale(lang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
      this.ref.detectChanges();
    });
    this.titleService.setTitle(this.CUSTOMIZATION.pageTitle);
    this.setDebugContext();
  }

  ngAfterViewInit() {
    this.toastrService.overlayContainer = this.toastContainer;
  }

  setDebugContext() {
    try {
      (window as any).context = {
        environment,
        config: this.config,
        worker: this.worker,
      };
    } catch (err) {
      console.error(err);
    }
  }
}
