import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '@Services/auth.service';
import { OrgaService } from '@Services/orga.service';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private auth: AuthService, private orgaService: OrgaService) {
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return timer(200).pipe(
      mergeMap(() => {
        if (route.data?.preload) {
          if (route.data.preload === 'always'
            || (route.data.preload === 'authenticated' && this.auth.isAuthenticated())
            || (route.data.preload === 'acl' && this.orgaService.offers && this.orgaService.hasOffer(route.data.offers))) {
            return load();
          }
        }
        return of(null);
      }),
    );
  }
}
