import { Pipe, PipeTransform } from '@angular/core';
import { RegistryService } from '@fairandsmart/angular';
import { FairQueryHelper, RegistryClass } from '@fairandsmart/types';

@Pipe({
  name: 'registryView',
  pure: false,
})
export class RegistryViewPipe implements PipeTransform {
  constructor(private registryService: RegistryService) {
  }

  public transform(
    value: any,
    classNameOrQuery: string | RegistryClass,
    relevancyIndex: number = 100,
    separator: string = ', ',
    locale: string = 'fr',
  ) {
    if (!classNameOrQuery) {
      console.warn('RegistryViewPipe : You must specify a class name or a query.');
      return value;
    }
    let registryClass: RegistryClass;
    if (typeof classNameOrQuery === 'string') {
      const className = FairQueryHelper.getClassFromExp(classNameOrQuery);
      registryClass = this.registryService.getRegistry().getClass(className, 'name');
    } else {
      registryClass = classNameOrQuery;
    }
    if (!registryClass) {
      console.warn(`RegistryViewPipe : Cannot find correponding view for ${classNameOrQuery}. You should pass the name of the class.`);
      return value;
    }
    return registryClass.toString((value instanceof Array) ? value[0] : value, relevancyIndex, separator, locale);
  }
}
