import { NgModule } from '@angular/core';
import { FsOrgBaseModule } from '@Common/base.module';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { MembershipInvitationComponent } from './membership-invitation/membership-invitation.component';

@NgModule({
  declarations: [
    WelcomePageComponent,
    MembershipInvitationComponent,
  ],
  imports: [
    FsOrgBaseModule,
  ],
})
export class ConnectionPagesModule { }
