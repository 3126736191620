import {
  OrganisationAction, OrganisationOffer, OrganisationStatus, OrganisationType,
} from '@fairandsmart/types';

export interface OrganisationAcl {
  statuses?: OrganisationStatus[];
  types?: OrganisationType[];
  offers?: OrganisationOffer[];
  actions?: OrganisationAction[];
}

export const ACL = {
  dashboard: {
    statuses: [OrganisationStatus.PENDING, OrganisationStatus.ACTIVE],
  },
  data: {
    statuses: [OrganisationStatus.ACTIVE],
    offers: [OrganisationOffer.DATA, OrganisationOffer.DATA_RO, OrganisationOffer.DATA_TRIAL],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.CONNECTION_SECTION],
    partners: [OrganisationAction.CONNECTION_SECTION],
  },
  requests: {
    statuses: [OrganisationStatus.ACTIVE],
    offers: [OrganisationOffer.RGPD, OrganisationOffer.RGPD_RO, OrganisationOffer.RGPD_TRIAL],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT, OrganisationType.VIRTUAL],
    actions: [OrganisationAction.REQUEST_SECTION],
  },
  'requests.inbox': {
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.REQUEST_SECTION],
  },
  'requests.response-templates': {
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.REQUEST_RESPONSE_TEMPLATE_SECTION],
  },
  'requests.config': {
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.CONFIG_REQUEST_WHITELIST],
  },
  'requests.identification': {
    types: [OrganisationType.STANDARD, OrganisationType.ROOT, OrganisationType.VIRTUAL],
    actions: [OrganisationAction.CONFIG_PARAMETERS_MANAGE],
  },
  'requests.gdpr-rights': {
    types: [OrganisationType.VIRTUAL, OrganisationType.ROOT],
    actions: [OrganisationAction.CONFIG_PARAMETERS_MANAGE],
  },
  'requests.registry-config': {
    types: [OrganisationType.STANDARD, OrganisationType.ROOT, OrganisationType.VIRTUAL],
    actions: [OrganisationAction.CONFIG_REGISTRY_MANAGE],
  },
  cookies: {
    statuses: [OrganisationStatus.ACTIVE],
    offers: [OrganisationOffer.COOKIES, OrganisationOffer.COOKIES_RO, OrganisationOffer.COOKIES_TRIAL],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.COOKIES_CONFIG_SECTION],
  },
  'right-consents': {
    statuses: [OrganisationStatus.ACTIVE],
    offers: [OrganisationOffer.CM, OrganisationOffer.CM_RO, OrganisationOffer.CM_TRIAL],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.CM_SECTION],
    enforceConsentManagerAvailability: true,
  },
  'right-consents.dashboard': {
    actions: [OrganisationAction.CM_SECTION],
    enforceConsentManagerAvailability: true,
  },
  'right-consents.subjects': {
    actions: [OrganisationAction.CM_OPERATE],
    enforceConsentManagerAvailability: true,
  },
  'right-consents.config': {
    actions: [OrganisationAction.CM_MODEL_SECTION],
    enforceConsentManagerAvailability: true,
  },
  'right-consents.collect': {
    actions: [OrganisationAction.CM_OPERATE],
    enforceConsentManagerAvailability: true,
  },
  'right-consents.interrogate': {
    actions: [OrganisationAction.CM_OPERATE],
    enforceConsentManagerAvailability: true,
  },
  consents: {
    statuses: [OrganisationStatus.ACTIVE],
    offers: [OrganisationOffer.CONSENT, OrganisationOffer.CONSENT_RO, OrganisationOffer.CONSENT_TRIAL],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.CONSENT_SECTION],
  },
  'consents.registry': {
    actions: [OrganisationAction.CONSENT_SECTION],
  },
  'consents.models': {
    actions: [OrganisationAction.CONSENT_MODEL_SECTION],
  },
  'consents.emails': {
    actions: [OrganisationAction.CONSENT_EMAIL_MODEL_SECTION],
  },
  'consents.preferences': {
    actions: [OrganisationAction.PREFERENCE_TYPE_SECTION],
  },
  'consents.treatments': {
    actions: [OrganisationAction.TREATMENT_SECTION],
  },
  'consents.retention': {
    actions: [OrganisationAction.CONSENT_DELETION_SECTION],
  },
  'configuration.information': {
    actions: [OrganisationAction.CONFIG_INFO_MANAGE],
  },
  'configuration.roles': {
    statuses: [OrganisationStatus.ACTIVE],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.CONFIG_ROLE_SECTION],
  },
  'configuration.members': {
    statuses: [OrganisationStatus.ACTIVE],
    actions: [OrganisationAction.CONFIG_MEMBER_SECTION],
  },
  'configuration.integration': {
    statuses: [OrganisationStatus.ACTIVE],
    actions: [OrganisationAction.CONNECTORS_SECTION],
  },
  'configuration.cpp': {
    statuses: [OrganisationStatus.ACTIVE],
    actions: [OrganisationAction.CONFIG_INFO_MANAGE],
  },
  'configuration.connectors': {
    statuses: [OrganisationStatus.ACTIVE],
    actions: [OrganisationAction.CONNECTORS_SECTION],
  },
  'configuration.offer': {
    statuses: [OrganisationStatus.ACTIVE],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.CUSTOMER_VIEW],
  },
  marketing: {
    statuses: [OrganisationStatus.ACTIVE],
    offers: [OrganisationOffer.MARKETING, OrganisationOffer.MARKETING_RO, OrganisationOffer.MARKETING_TRIAL],
    types: [OrganisationType.STANDARD, OrganisationType.ROOT],
    actions: [OrganisationAction.MARKETING_SECTION],
  },
  logs: {
    actions: [OrganisationAction.EVENTS_SEARCH],
  },
  partial: {
    oneAction: true,
    actions: [OrganisationAction.CONSENT_SECTION, OrganisationAction.CM_SECTION, OrganisationAction.CONNECTION_SECTION, OrganisationAction.REQUEST_SECTION, OrganisationAction.COOKIES_CONFIG_SECTION],
  },
};
