import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'i18nMap',
})
export class I18nMapPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(value: any, locale: string = this.translate.currentLang, defaultLocale: string = 'en'): string {
    if (Object.keys(value).length === 0) {
      return '';
    }
    if (value[locale] != null) {
      return value[locale];
    }
    if (value[defaultLocale] != null) {
      return value[defaultLocale];
    }
    return value[Object.keys(value)[0]];
  }
}
