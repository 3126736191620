import { Pipe, PipeTransform } from '@angular/core';
import {
  AbstractEntity, EntityIdentifierHelper, EntityName, EntityReference, FsConnection,
} from '@fairandsmart/types';
import { FsConnectionService, FsOrganisationService, FsProfileService } from '@fairandsmart/angular';
import { Observable, of } from 'rxjs';
import { map, mergeMap, zipAll } from 'rxjs/operators';
import { OrgaService } from '@Services/orga.service';

@Pipe({
  name: 'referenceToName',
})
export class ReferenceToNamePipe implements PipeTransform {
  constructor(
    private fsOrganistationService: FsOrganisationService,
    private fsConnectionService: FsConnectionService,
    private orgaService: OrgaService,
    private fsProfileService: FsProfileService,
  ) {
  }

  transform(value: EntityReference, entityName?: string, entity?: AbstractEntity): Observable<string> {
    const type = entityName || EntityIdentifierHelper.extractType(value);
    switch (type) {
      case EntityName.RECIPIENT:
        return of(EntityIdentifierHelper.extractId(value));
      case EntityName.ORGANISATION:
        return this.fsOrganistationService.get(EntityIdentifierHelper.extractId(value)).pipe(map((orga) => orga.name));
      case EntityName.CONNECTION: {
        const fetch$ = entity ? of(entity) : this.fsConnectionService.get(value);
        return fetch$.pipe(
          mergeMap((connection: FsConnection) => {
            const endpoints = connection.endpoints.filter((ep) => ep.entityIdentifier.entityId !== this.orgaService.id);
            return endpoints.map((endpoint) => {
              if (endpoint.entityIdentifier.entityName === EntityName.ORGANISATION) {
                return this.fsOrganistationService.get(endpoint.entityIdentifier.entityId).pipe(map((orga) => orga.name));
              }
              if (endpoint.entityIdentifier.entityName === EntityName.PROFILE) {
                return this.fsProfileService.get(endpoint.entityIdentifier.entityId).pipe(map((profile) => profile.email));
              }
              return of(endpoint.entityIdentifier.entityId);
            });
          }),
          zipAll(),
          map((results: string[]) => results.join(', ')),
        );
      }
      default:
        return of(value);
    }
  }
}
