<h2 mat-dialog-title>{{'RIGHT_CONSENTS.SERVICE_DOWN.TITLE' | translate }}</h2>
<mat-dialog-content>{{'RIGHT_CONSENTS.SERVICE_DOWN.CONTENT' | translate }}</mat-dialog-content>
<mat-dialog-actions class="btn-uppercase align-right">
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{'COMMON.BUTTONS.CLOSE' | translate }}
  </button>
  <button mat-button color="primary" [mat-dialog-close]="true">
    {{'COMMON.BUTTONS.RETRY' | translate }}
  </button>
</mat-dialog-actions>
