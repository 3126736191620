import { Injectable } from '@angular/core';
import {
  concat, from, NEVER, Observable, of, ReplaySubject, Subject, zip,
} from 'rxjs';
import {
  DataFormat,
  DataStatus,
  DecipheredData,
  EntityCollection,
  EntityIdentifierHelper,
  FairQueryHelper,
  FsConnection,
  FsData,
  IdentificationField,
  OrganisationType,
  Pds,
} from '@fairandsmart/types';
import { FsCryptService, FsDataService } from '@fairandsmart/angular';
import {
  catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, tap, toArray,
} from 'rxjs/operators';
import PouchDB from 'pouchdb';
import findPlugin from 'pouchdb-find';
import { OrgaService } from './orga.service';
import { ConnectionService } from './connection.service';
import { AlertService } from './alert.service';
import { BackgroundService } from '../workers/worker.service';

export interface PDEItem {
  _id: string;
  _rev?: any;
  _deleted?: boolean;
  version: number;
  reference: string;
  subjectId: string;
  lastUpdated: number;
  idFields: { [idFieldName: string]: any };
  data: Pds;
}

export type PDEContent = Array<PDEItem>;

export enum DataExtractorStatus {
  INIT,
  PROCESSING,
  UPDATING,
  REINDEXING,
  READY,
  ERROR,
  SHOULD_NOT_INDEX,
}

export interface IndexingOption {
  forceCheck?: boolean;
}

@Injectable({ providedIn: 'root' })
export class DataExtractorService {
  readonly CRAWL_LIMIT = 100;

  readonly CPP_PDS_TAG = 'cpp_master_pds';

  readonly PDE_TAG = 'pde_content';

  readonly PDE_LOCAL_KEY = 'fs_pde_content';

  readonly DEFAULT_PDE = [];

  readonly CURRENT_PDEITEM_VERSION = 1;

  queue: Subject<{ data: FsData, forceCheck: boolean }>;

  content: PDEContent;

  autoSave$: Subject<void>;

  pdeData: DecipheredData<PDEContent>;

  db: PouchDB.Database<PDEItem>;

  indexing$ = new Subject<IndexingOption>();

  private _status: DataExtractorStatus;

  private status$ = new ReplaySubject<DataExtractorStatus>();

  idFields: IdentificationField[];

  private set status(status: DataExtractorStatus) {
    this._status = status;
    this.status$.next(this._status);
  }

  private get status(): DataExtractorStatus {
    return this._status;
  }

  constructor(
    private fsDataService: FsDataService,
    private orgaService: OrgaService,
    private cryptService: FsCryptService,
    private connectionService: ConnectionService,
    private alertService: AlertService,
    private backgroundService: BackgroundService,
  ) {
  }

  getStatus(): Observable<DataExtractorStatus> {
    return this.status$;
  }

  init(): Observable<any> {
    if (this.status === DataExtractorStatus.SHOULD_NOT_INDEX || this.orgaService.type === OrganisationType.VIRTUAL) {
      this.status = DataExtractorStatus.SHOULD_NOT_INDEX;
      return of(true);
    }
    this.status = DataExtractorStatus.INIT;
    this.queue = new Subject<{ data: FsData, forceCheck: boolean }>();
    PouchDB.plugin(findPlugin);
    this.db = new PouchDB<PDEItem>(`fs-pde-${this.orgaService.alias}`);
    this.db.getIndexes();
    return this.getPDEContent().pipe(
      filter((hasContent) => !!hasContent),
      tap(() => {
        this.processQueue();
        this.startBackgroundExtraction();
      }),
      catchError((err) => {
        console.error(err);
        this.alertService.error('COMMON.ERRORS.DATA_EXTRACT_UNAVAILABLE');
        return of(null);
      }),
    );
  }

  indexData() {
    if (this.status !== DataExtractorStatus.SHOULD_NOT_INDEX) {
      this.indexing$.next({ forceCheck: true });
    }
  }

  // Interogation functions

  findData(dataSearchOptions: PouchDB.Find.FindRequest<PDEItem>) {
    return from(this.db.find(dataSearchOptions));
  }

  // Internals

  private applyPdeData(pde) {
    this.pdeData = pde;
    this.content = pde.clearContent;
    return this.db.bulkDocs(this.content);
  }

  private getPDEContent() {
    return this.fsDataService.search({
      tags: [this.PDE_TAG],
      reference: this.orgaService.reference,
      status: DataStatus.ACTIVE,
      format: DataFormat.PDE,
    }).pipe(
      mergeMap((result) => {
        if (result.size > 0) {
          return this.backgroundService.getData<PDEContent>(result.values[0].id, { ciphererId: this.orgaService.id, parseContent: true });
        }
        return this.createRemotePDE(this.DEFAULT_PDE);
      }),
      mergeMap((pdeData) => this.applyPdeData(pdeData)),
      mergeMap(() => this.orgaService.getFieldsForOrganisation()),
      tap((fields) => { this.idFields = fields; }),
      catchError((err) => {
        console.error('[PDE] Error while intialization of remote PDE', err);
        if (!this.content) {
          this.content = [];
        }
        return of(this.content);
      }),
    );
  }

  private processQueue() {
    this.queue.pipe(
      // Convert to concatMap if performances are impacted
      filter((queueItem) => {
        const item = this.content.find((c) => c._id === queueItem.data.id);
        return (queueItem?.forceCheck || !item || item.version !== this.CURRENT_PDEITEM_VERSION || item.lastUpdated < queueItem.data.modificationDate);
      }),
      mergeMap((queueItem) => zip(
        this.backgroundService.getData<Pds>(queueItem.data.id, {
          parseContent: true,
          ciphererId: this.orgaService.id,
        }),
        this.connectionService.get(EntityIdentifierHelper.extractId(queueItem.data.reference)),
      )),
      mergeMap(([clearData, connection]) => this.saveToPDE(clearData, connection)),
      filter((result) => result?.updated),
      tap(() => this.autoSave$.next()), // Trigger autosave timer
    ).subscribe();
  }

  private startBackgroundExtraction() {
    if (this.status === DataExtractorStatus.PROCESSING) { return; }
    this.status = DataExtractorStatus.PROCESSING;
    this.indexing$.pipe(
      switchMap((options: IndexingOption) => this.fsDataService.search({
        tags: [this.CPP_PDS_TAG],
        limit: this.CRAWL_LIMIT, // CRAWL LIMIT - already crawled
      }).pipe(
        mergeMap((result: EntityCollection<FsData>) => from(result.values)),
        tap((data: FsData) => this.queue.next({ data, forceCheck: options?.forceCheck })),
        toArray(),
      )),
      mergeMap(() => this.createIndexes()),
    ).subscribe(() => {
      this.status = DataExtractorStatus.READY;
    });
    this.indexing$.next({ forceCheck: false });
    // LOAD ALL DATA WITH TAG CPP_DATA AND CHECK AGAINST JOURNAL

    // SAVE AFTER UPDATE AND TIME
    this.autoSave$ = new Subject<void>();
    this.autoSave$.pipe(
      switchMap(() => of(this.content)),
      debounceTime(2500), // Autosave 2.5s after last successful insert. Not ideal with throttled or slow connections
      mergeMap((contentToSave) => this.uploadPDE(contentToSave)),
    ).subscribe();
  }

  private uploadPDE(content: PDEContent) {
    if (this.status === DataExtractorStatus.UPDATING) { return NEVER; }
    this.status = DataExtractorStatus.UPDATING;
    let pde$ = this.updateRemotePDE(content);
    if (!this.pdeData) {
      pde$ = this.createRemotePDE(content);
    }
    return pde$.pipe(
      mergeMap((pdeData) => this.applyPdeData(pdeData)),
      tap(() => { this.status = DataExtractorStatus.READY; }),
    );
  }

  private saveToPDE(clearData: DecipheredData<Pds>, connection: FsConnection): Observable<{ updated: boolean }> {
    const idFields = this.extractIdentificationFieldsFromData(clearData.clearContent);
    const toInsert: PDEItem = {
      lastUpdated: clearData.modificationDate,
      reference: clearData.reference,
      subjectId: this.extractSubjectFromConnection(connection),
      _id: clearData.id,
      idFields,
      version: this.CURRENT_PDEITEM_VERSION,
      data: clearData.clearContent,
    };
    const shouldIndex = Object.keys(idFields).length > 0;
    return from(this.db.get(toInsert._id)).pipe(
      mergeMap((doc) => {
        if (!doc && shouldIndex) {
          return this.db.put(toInsert);
        }
        if (doc) {
          toInsert._rev = doc._rev;
          if (shouldIndex === false) {
            // If there are no idfield matching in the data anymore, data should be removed from indexer
            toInsert._deleted = true;
          }
          return this.db.put(toInsert);
        }
        return of(undefined);
      }),
      map((inserted) => ({ updated: !!inserted })),
      catchError((err) => {
        if (err?.status === 404) {
          if (shouldIndex) {
            // Document does not exist
            return from(this.db.put(toInsert)).pipe(map(() => ({ updated: true })));
          }
          return of({ updated: false });
        }
        console.error('[PDE] Could not insert/update document', err);
        return of({ updated: false });
      }),
      mergeMap((result) => {
        if (result.updated === true) {
          return from(this.db.allDocs<PDEItem>({ include_docs: true })).pipe(map((docs) => {
            this.content = docs.rows.filter((row) => Object.prototype.hasOwnProperty.call(row.doc || {}, 'lastUpdated')).map((row) => row.doc);
            return result;
          }));
        }
        return of(result);
      }),
    );
  }

  private extractSubjectFromConnection(connection: FsConnection) {
    const endpoint = connection.endpoints.find((e) => e.entityIdentifier.entityId !== this.orgaService.id);
    return endpoint?.identifier;
  }

  private extractIdentificationFieldsFromData(data: Pds) {
    const mappedFields = this.idFields.map((field) => {
      const result = FairQueryHelper.apply(data, field.query);
      if (result.length === 0) {
        return undefined;
      }
      return { fieldKey: field.name, result: result[0] };
    });
    return mappedFields
      .filter((f) => !!f)
      .reduce((acc, cur) => ({ ...acc, [cur.fieldKey]: cur.result.value }), {});
  }

  private createIndexes() {
    if (!this.idFields) { return of([]); }
    return from(this.db.getIndexes()).pipe(
      map((indexes) => {
        const alreadyIndexed = indexes.indexes.map((i) => Object.keys(i.def.fields[0])[0].split('.')[1]);
        const toAdd = this.idFields.filter((f) => alreadyIndexed.indexOf(f.name) === -1);
        const toRemove = indexes.indexes.filter((idx) => {
          const idName = Object.keys(idx.def.fields[0])[0].split('.')[1];
          return idx.ddoc && this.idFields.findIndex((field) => field.name === idName) === -1;
        });
        return { toAdd, toRemove };
      }),
      switchMap((indexConfig) => {
        const add$ = from(indexConfig.toAdd).pipe(
          concatMap((field) => this.db.createIndex({ index: { fields: [`idFields.${field.name}`] } })),
          catchError((err) => { console.error(err); return of(undefined); }),
          toArray(),
        );
        const remove$ = from(indexConfig.toRemove).pipe(
          concatMap((idx) => this.db.deleteIndex(idx)),
          catchError((err) => { console.error(err); return of(undefined); }),
          toArray(),
        );
        return concat(add$, remove$);
      }),
      toArray(),
    );
  }

  private createRemotePDE(content: PDEContent): Observable<DecipheredData<PDEContent>> {
    return from(this.cryptService.generateCSK()).pipe(
      mergeMap((sessionKey) => this.backgroundService.createData<PDEContent>({
        content,
        ciphererId: this.orgaService.id,
        format: DataFormat.PDE_V,
        reference: this.orgaService.reference,
        endpoint: this.orgaService.reference,
        source: this.orgaService.reference,
        sessionKey,
        wrapResponse: true,
        tags: [this.PDE_TAG],
      })),
    );
  }

  private updateRemotePDE(content: PDEContent): Observable<DecipheredData<PDEContent>> {
    return this.backgroundService.updateData<PDEContent>({
      content,
      ciphererId: this.orgaService.id,
      dataId: this.pdeData.id,
      sessionKeys: this.pdeData.getKeys(1),
      wrapResponse: true,
      endpoint: this.orgaService.reference,
      format: DataFormat.PDE_V,
      tags: [this.PDE_TAG],
    });
  }
}
