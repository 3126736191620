import { Component, Input, OnInit } from '@angular/core';
import { EntityIdentifier, EntityIdentifierHelper, EntityName } from '@fairandsmart/types';
import { FsProfileService } from '@fairandsmart/angular';
import { AlertService } from '@Services/alert.service';

@Component({
  selector: 'fs-entity-identifier-display',
  templateUrl: './entity-identifier-display.component.html',
  styleUrls: ['./entity-identifier-display.component.scss'],
})
export class EntityIdentifierDisplayComponent implements OnInit {
  @Input() entityReference: string;

  @Input() entityIdentifier: EntityIdentifier;

  externalId: string;

  readonly EntityName = EntityName;

  constructor(
    private profileService: FsProfileService,
    private alert: AlertService,
  ) { }

  ngOnInit(): void {
    if (this.entityReference && !this.entityIdentifier) {
      this.entityIdentifier = EntityIdentifierHelper.toEntityIdentifier(this.entityReference);
    }
    if (this.entityIdentifier.entityName === EntityName.PROFILE) {
      this.profileService.get(this.entityIdentifier.entityId).subscribe((profile) => {
        [,this.externalId] = profile.externalId.split('/');
      });
    }
  }

  copied() {
    this.alert.success('COMMON.COPIED');
  }
}
