import { Pipe, PipeTransform } from '@angular/core';
import {
  RegistryEntry,
  RegistryEntryHelper,
  RegistryEntryTranslatableFields,
  TranslationDto,
} from '@fairandsmart/types';

@Pipe({
  name: 'registryEntryField',
  pure: false,
})
export class RegistryEntryFieldPipe implements PipeTransform {
  public transform(value: { i18n?: TranslationDto[], [key: string]: any }, field: RegistryEntryTranslatableFields, lang: string): string {
    if (!value) {
      console.warn('RegistryEntryFieldPipe : You must specify an entry.');
      return '';
    }
    if (typeof value === 'string') { return value; }
    return RegistryEntryHelper.getTranslation(value as RegistryEntry, field, lang)?.value || `[${lang} ${field}]`;
  }
}
