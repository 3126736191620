
String.prototype.capitalize = function() {
  if (this.length > 0) {
    return this.charAt(0).toUpperCase() + this.slice(1);
  } else {
    return this.toString();
  }
};

String.prototype.removeSpaces = function() {
  if (this.length > 0) {
    return this.replace(/\s/g, '');
  } else {
    return this.toString();
  }
};
