import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

export interface ErrorDialogData {
  error: HttpErrorResponse;
}

@Component({
  selector: 'fs-error-dialog',
  templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorDialogData) {
  }

  reload(): void {
    window.location.reload();
  }
}
