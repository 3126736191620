import {
  Component, Input, OnChanges, OnDestroy, OnInit, Renderer2,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'fs-default-language-helper',
  templateUrl: './default-language-helper.html',
  styleUrls: ['./default-language-helper.scss'],
})
export class DefaultLanguageHelper implements OnInit, OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup;

  @Input() group: string;

  @Input() key: string;

  @Input() currentLanguage: string;

  @Input() i18nPrefix: string;

  textValue: string;

  arrayValue: string[] = [];

  isBoolean: boolean;

  hidden: boolean;

  defaultLanguage: string;

  changesSubscriber;

  showText = true;

  constructor(public renderer: Renderer2) {
  }

  ngOnInit() {
    this.changesSubscriber = this.form.valueChanges.subscribe(() => this.refresh());
  }

  ngOnChanges() {
    this.refresh();
  }

  ngOnDestroy() {
    if (this.changesSubscriber) {
      this.changesSubscriber.unsubscribe();
    }
  }

  refresh() {
    this.hidden = false;
    this.defaultLanguage = this.form.get('defaultLanguage').value;
    if (!this.defaultLanguage) {
      this.hidden = true;
      return;
    }
    if (this.currentLanguage === this.defaultLanguage) {
      this.hidden = true;
      return;
    }
    let value;
    if (this.group) {
      value = this.form?.get('data')?.get(this.defaultLanguage)?.get(this.group)?.get(this.key)?.value;
    } else {
      value = this.form?.get('data')?.get(this.defaultLanguage)?.get(this.key)?.value;
    }
    if (Array.isArray(value)) {
      this.arrayValue = value;
    } else if (typeof value === 'boolean') {
      this.isBoolean = true;
      this.textValue = value.toString();
    } else {
      this.textValue = value;
    }
  }
}
