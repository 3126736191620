import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FsCryptService, FsOrganisationService, FsNewsService } from '@fairandsmart/angular';
import {
  BiKey,
  CPPConfigParameterValue,
  EntityIdentifier,
  EntityIdentifierHelper,
  EntityName,
  EntityReference,
  IdentificationField,
  MemberView,
  News,
  OrganisationCPPConfig,
  OrganisationOffer,
  OrganisationOfferProduct,
  OrganisationOfferService,
  OrganisationRole,
  OrganisationStatus,
  OrganisationType,
  OrganisationView,
  Parameter,
  ParameterKey,
  RequestFieldsParameterValue,
  RequestNotificationOptions,
} from '@fairandsmart/types';
import * as _ from 'lodash';
import {
  BehaviorSubject, Observable, of, throwError, zip,
} from 'rxjs';
import {
  catchError, map, mergeMap, tap,
} from 'rxjs/operators';
import { RightConsents } from '@fairandsmart/consents';
import { ORG_PREFIX } from '@Common/constants';
import {
  FairCodeDialogComponent,
  FairCodeDialogComponentData,
  FairCodeDialogResult,
} from '@Common/fair-code-dialog/fair-code-dialog.component';
import { BackgroundService } from '../workers/worker.service';

@Injectable({
  providedIn: 'root',
})
export class OrgaService {
  private connectedOrgaInternal: OrganisationView;

  private changeSource: BehaviorSubject<OrganisationView>;

  change: Observable<OrganisationView>;

  private offersChangeSource: BehaviorSubject<OrganisationOffer[]>;

  offersChange: Observable<OrganisationOffer[]>;

  private rolesChangeSource: BehaviorSubject<OrganisationRole[]>;

  rolesChange: Observable<OrganisationRole[]>;

  private _offers: OrganisationOffer[];

  private productsInternal: Map<string, OrganisationOfferService>;

  private rolesInternal: OrganisationRole[];

  private membersInternal: MemberView[];

  private membersMapInternal: { [userid: string]: MemberView };

  private fields: IdentificationField[];

  private cppConfig: OrganisationCPPConfig;

  constructor(
    private fsOrganisationService: FsOrganisationService,
    private fsNewsService: FsNewsService,
    private dialog: MatDialog,
    private router: Router,
    private crypt: FsCryptService,
    private backgroundService: BackgroundService,
  ) {
    this.changeSource = new BehaviorSubject<OrganisationView>(null);
    this.change = this.changeSource.asObservable();
    this.offersChangeSource = new BehaviorSubject<OrganisationOffer[]>(null);
    this.offersChange = this.offersChangeSource.asObservable();
    this.rolesChangeSource = new BehaviorSubject<OrganisationRole[]>(null);
    this.rolesChange = this.rolesChangeSource.asObservable();
  }

  private get connectedOrga(): OrganisationView {
    return this.connectedOrgaInternal || {} as OrganisationView;
  }

  private set connectedOrga(value: OrganisationView) {
    this.connectedOrgaInternal = value;
    this.changeSource.next(value);
  }

  get id(): string {
    return this.connectedOrga.id;
  }

  get alias(): string {
    return this.connectedOrga.alias;
  }

  get modificationDate(): number {
    return this.connectedOrga.modificationDate;
  }

  get name(): string {
    return this.connectedOrga.name;
  }

  get description(): string {
    return this.connectedOrga.description;
  }

  get category(): string {
    return this.connectedOrga.category;
  }

  get country(): string {
    return this.connectedOrga.country;
  }

  get reference(): EntityReference {
    return EntityIdentifierHelper.toEntityReference(this.connectedOrgaInternal);
  }

  get members(): Readonly<MemberView>[] {
    return this.membersInternal;
  }

  get membersMap(): { [userid: string]: MemberView } {
    return this.membersMapInternal;
  }

  get type(): OrganisationType {
    return this.connectedOrga.type;
  }

  get status(): OrganisationStatus {
    return this.connectedOrga.status;
  }

  get infos(): { [key: string]: any } {
    return this.connectedOrga.infos;
  }

  get offers(): OrganisationOffer[] {
    return this._offers ?? [];
  }

  get products(): Map<string, OrganisationOfferService> {
    return this.productsInternal ?? new Map();
  }

  get roles(): OrganisationRole[] {
    return this.rolesInternal ?? [];
  }

  get customerId(): string {
    return this.connectedOrga.customerId;
  }

  public switchOrganisation(): Promise<boolean> {
    this.resetOrganisation();
    return this.router.navigate(['/home']);
  }

  private resetOrganisation(): void {
    this.connectedOrga = null;
    this._offers = null;
    this.productsInternal = null;
    this.offersChangeSource.next(null);
    this.rolesChangeSource.next(null);
    this.rolesInternal = null;
    this.crypt.lockPrivateKey();
    this.backgroundService.purgeCrypt();
    RightConsents.reset();
  }

  public refreshOrganisation(): Observable<OrganisationView> {
    return this.fsOrganisationService.get(this.id).pipe(
      tap((organisation: OrganisationView) => {
        this.connectedOrga = organisation;
      }),
    );
  }

  public connect(alias: string, userid: string): Observable<boolean> {
    if (this.alias === alias && this.crypt.isPrivateKeyReleased()) {
      return of(true);
    }
    return this.fsOrganisationService.getByAlias(alias).pipe(
      mergeMap((organisation: OrganisationView) => zip(
        this.loadOffers(organisation.id),
        this.loadRoles(organisation.id),
        this.loadMembers(organisation.id),
      ).pipe(
        mergeMap(() => {
          if (organisation.type === OrganisationType.VIRTUAL) {
            this.connectedOrga = organisation;
            return of(true);
          }
          return this.fsOrganisationService.getMemberBiKey(organisation.id, userid).pipe(
            mergeMap((bikey: BiKey) => this.dialog.open<FairCodeDialogComponent, FairCodeDialogComponentData>(FairCodeDialogComponent, {
              width: '420px',
              disableClose: true,
              data: {
                id: organisation.id,
                name: organisation.name,
                alias: organisation.alias,
                orgaService: this as OrgaService,
                bikey,
              },
            }).afterClosed()),
            mergeMap((state: FairCodeDialogResult) => {
              switch (state) {
                case FairCodeDialogResult.RELEASED:
                  this.connectedOrga = organisation;
                  return of(true);
                case FairCodeDialogResult.CANCELLED:
                  this.dialog.closeAll();
                  return of(false);
                default:
                  return throwError('Cancel private key release');
              }
            }),
          );
        }),
      )),
      catchError((err) => {
        this.resetOrganisation();
        return throwError(err);
      }),
    );
  }

  public loadMembers(id?: string): Observable<MemberView[]> {
    return this.fsOrganisationService.getMembers(id || this.id).pipe(
      tap((members) => {
        this.membersInternal = members;
        this.membersMapInternal = _.keyBy(members, 'userid');
      }),
    );
  }

  public loadOffers(id?: string): Observable<OrganisationOffer[]> {
    return this.fsOrganisationService.listOffers(id || this.id).pipe(
      tap((offers) => {
        this._offers = offers;
        this.productsInternal = new Map([
          ['RGPD', this.getServiceForProduct(OrganisationOfferProduct.RGPD)],
          ['RIGHT_CONSENTS', this.getServiceForProduct(OrganisationOfferProduct.CM)],
          ['CONSENT_LEGACY', this.getServiceForProduct(OrganisationOfferProduct.CONSENT)],
          ['DATA', this.getServiceForProduct(OrganisationOfferProduct.DATA)],
          ['COOKIES', this.getServiceForProduct(OrganisationOfferProduct.COOKIES)],
        ]);
        this.offersChangeSource.next(offers);
      }),
    );
  }

  public loadRoles(id?: string): Observable<OrganisationRole[]> {
    return this.fsOrganisationService.listRoles(id || this.id).pipe(
      tap((roles) => {
        this.rolesInternal = roles;
        this.rolesChangeSource.next(roles);
      }),
    );
  }

  public listNews(): Observable<News[]> {
    return this.fsNewsService.listNews([this.alias]);
  }

  public markNewsAsRead(newsid: string): Observable<void> {
    return this.fsNewsService.markNewsAsRead(newsid);
  }

  public markNewsAsUnRead(newsid: string): Observable<void> {
    return this.fsNewsService.markNewsAsUnread(newsid);
  }

  loadCPPConfig(): Observable<OrganisationCPPConfig> {
    return this.fsOrganisationService.getParameter<CPPConfigParameterValue>(this.id, ParameterKey.CPP_CONFIG).pipe(
      map((param: Parameter<CPPConfigParameterValue>) => (param?.values?.stringifiedConfig ? JSON.parse(param?.values?.stringifiedConfig) : null)),
      tap((config) => { this.cppConfig = config; }),
    );
  }

  getFieldsForOrganisation(force: boolean = false): Observable<IdentificationField[]> {
    if (this.fields && !force) {
      return of(this.fields);
    }
    return this.fsOrganisationService.getParameter(this.id, ParameterKey.REQUEST_FIELDS).pipe(
      map((param: Parameter<RequestFieldsParameterValue>) => JSON.parse(param.values.fields)),
      tap((fields) => { this.fields = fields; }),
    );
  }

  getRequestNotificationOptionsForOrganisation(): Observable<RequestNotificationOptions> {
    return this.fsOrganisationService.getParameter(this.id, ParameterKey.REQUEST_UNSECURE_NOTIFICATION_OPTIONS).pipe(
      map((param: Parameter<RequestNotificationOptions>) => param.values),
    );
  }

  isOrganisationOfType(types: OrganisationType | OrganisationType[]): boolean {
    return Array.isArray(types) ? types.includes(this.type) : this.type === types;
  }

  hasStatus(statuses: OrganisationStatus | OrganisationStatus[]): boolean {
    return Array.isArray(statuses) ? statuses.includes(this.status) : this.status === statuses;
  }

  hasOffer(offers: OrganisationOffer | OrganisationOffer[]): boolean {
    return (Array.isArray(offers) ? offers : [offers]).some((offer) => this.offers.includes(offer));
  }

  getOfferForProduct(product: OrganisationOfferProduct): OrganisationOffer {
    return this.offers.find((value: string) => OrganisationOffer[value].startsWith(OrganisationOfferProduct[product]));
  }

  hasOfferForProduct(product: OrganisationOfferProduct): boolean {
    return this.offers.some((offer: string) => OrganisationOffer[offer].startsWith(OrganisationOfferProduct[product]));
  }

  hasServiceForProduct(product: OrganisationOfferProduct, service: OrganisationOfferService): boolean {
    return this.offers.some((offer: string) => OrganisationOffer[offer].startsWith(OrganisationOfferProduct[product])
      && OrganisationOffer[offer].endsWith(OrganisationOfferService[service]));
  }

  getServiceForProduct(product: OrganisationOfferProduct): OrganisationOfferService {
    const offer = this.getOfferForProduct(product) as string;
    if (offer != null) {
      if (offer.indexOf('_') > 0) {
        return OrganisationOfferService[offer.split('_')[1]];
      }
      return OrganisationOfferService.FULL;
    }
    return OrganisationOfferService.NONE;
  }

  viewEntityDetail(entity: EntityIdentifier | EntityReference): Promise<boolean> {
    const identifier: EntityIdentifier = typeof entity === 'string' ? EntityIdentifierHelper.toEntityIdentifier(entity) : entity;
    switch (identifier.entityName) {
      case EntityName.REQUEST:
        return this.router.navigate([this.baseUrl, 'requests', 'inbox', identifier.entityId]);
      case EntityName.REQUEST_RESPONSE_TEMPLATE:
        return this.router.navigate([this.baseUrl, 'requests', 'response-templates', identifier.entityId]);
      case EntityName.CONNECTION:
        return this.router.navigate([this.baseUrl, 'data', 'connections', identifier.entityId]);
      case EntityName.CONSENT_MODEL:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'forms', identifier.entityId]);
      case EntityName.CONSENT_OPT_OUT_MODEL:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'emails', identifier.entityId]);
      case EntityName.COOKIES_CONFIG:
        return this.router.navigate([this.baseUrl, 'cookies', 'list', identifier.entityId]);
      case EntityName.TREATMENT:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'treatments', identifier.entityId]);
      case EntityName.PREFERENCE_TYPE:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'preferences', identifier.entityId]);
      default:
        return this.router.navigate([this.baseUrl]);
    }
  }

  hasEntityDetailView(entity: EntityIdentifier | EntityReference): boolean {
    const entityName: EntityName = typeof entity === 'string' ? EntityIdentifierHelper.extractType(entity) : entity.entityName;
    switch (entityName) {
      case EntityName.REQUEST:
      case EntityName.REQUEST_RESPONSE_TEMPLATE:
      case EntityName.CONNECTION:
      case EntityName.CONSENT_MODEL:
      case EntityName.CONSENT_OPT_OUT_MODEL:
      case EntityName.TREATMENT:
      case EntityName.PREFERENCE_TYPE:
      case EntityName.COOKIES_CONFIG:
      case EntityName.CONSENT:
        return true;
      default:
        return false;
    }
  }

  entityIcon(entity: EntityIdentifier | EntityReference): string {
    const entityName: EntityName = typeof entity === 'string' ? EntityIdentifierHelper.extractType(entity) : entity.entityName;
    switch (entityName) {
      case EntityName.PROFILE:
        return 'person_outline';
      case EntityName.REQUEST:
        return 'assignment_ind';
      case EntityName.REQUEST_RESPONSE_TEMPLATE:
        return 'reply';
      case EntityName.CONNECTION:
        return 'bubble_chart';
      case EntityName.CONSENT:
        return 'assignment_returned';
      case EntityName.CONSENT_MODEL:
        return 'assignment_turned_in';
      case EntityName.CONSENT_OPT_OUT_MODEL:
        return 'email';
      case EntityName.COOKIES_CONFIG:
        return 'privacy_tip';
      case EntityName.TREATMENT:
        return 'memory';
      case EntityName.PREFERENCE_TYPE:
        return 'ballot';
      default:
        return null;
    }
  }

  viewEntityList(entityName: EntityName, entityFilter?: any): Promise<boolean> {
    let queryParams;
    if (entityFilter != null) {
      queryParams = { filter: btoa(JSON.stringify(entityFilter)) };
    } else {
      queryParams = null;
    }
    switch (entityName) {
      case EntityName.REQUEST:
        return this.router.navigate([this.baseUrl, 'requests', 'inbox'], { queryParams });
      case EntityName.REQUEST_RESPONSE_TEMPLATE:
        return this.router.navigate([this.baseUrl, 'requests', 'response-templates'], { queryParams });
      case EntityName.CONNECTION:
        return this.router.navigate([this.baseUrl, 'data', 'connections'], { queryParams });
      case EntityName.CONSENT:
        return this.router.navigate([this.baseUrl, 'consents', 'registry'], { queryParams });
      case EntityName.CONSENT_MODEL:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'forms'], { queryParams });
      case EntityName.CONSENT_OPT_OUT_MODEL:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'emails'], { queryParams });
      case EntityName.TREATMENT:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'treatments'], { queryParams });
      case EntityName.PREFERENCE_TYPE:
        return this.router.navigate([this.baseUrl, 'consents', 'models', 'preferences'], { queryParams });
      default:
        return this.router.navigate([this.baseUrl], { queryParams });
    }
  }

  getConsentManagerLocation(): Observable<string> {
    return this.fsOrganisationService.getConsentManagerLocation(this.id);
  }

  get baseUrl(): string {
    return `/${ORG_PREFIX}/${this.alias}`;
  }
}
