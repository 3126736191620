<router-outlet #appOutlet="outlet">
  <div *ngIf="!appOutlet.isActivated" class="splash">
    <img alt="Loading" [src]="CUSTOMIZATION.splashLogoUrl" [style]="CUSTOMIZATION.splashLogoStyle">
    <div class="loading-dots">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</router-outlet>
<div toastContainer></div>
