import { EventMessage, FsErrorResponse } from '@fairandsmart/angular';
import { Parameter } from '@fairandsmart/types';
import { Action } from '@ngrx/store';

export enum CommonActionTypes {
  CoreEvent = '[Websocket] Core Event',
  LoadNotifications = '[Profile API] Load notifications',
  LoadNotificationsSuccess = '[Profile API] Load notifications success',
  LoadNotificationsFailure = '[Profile API] Load notifications failure',
  ShowTutorialNotification = '[Tutorial] Show tutorial notification',
  DismissTutorialNotification = '[Tutorial] Dismiss tutorial notification',
  ResetTutorialNotification = '[Tutorial] Reset tutorial notification',
}

export class CoreEvent implements Action {
  readonly type = CommonActionTypes.CoreEvent;

  constructor(public payload: EventMessage) {}
}

export class LoadNotifications implements Action {
  readonly type = CommonActionTypes.LoadNotifications;
}

export class LoadNotificationsSuccess implements Action {
  readonly type = CommonActionTypes.LoadNotificationsSuccess;

  constructor(public payload: { response: Parameter<{ [key: string]: boolean }> }) {}
}

export class LoadNotificationsFailure implements Action {
  readonly type = CommonActionTypes.LoadNotificationsFailure;

  constructor(public payload: { response: FsErrorResponse }) {}
}

export class ShowTutorialNotification implements Action {
  readonly type = CommonActionTypes.ShowTutorialNotification;
}

export class DismissTutorialNotification implements Action {
  readonly type = CommonActionTypes.DismissTutorialNotification;

  constructor(public payload: { [key: string]: boolean | string }) {
  }
}

export class ResetTutorialNotification implements Action {
  readonly type = CommonActionTypes.ResetTutorialNotification;
}

export type CommonActions = CoreEvent | LoadNotifications | LoadNotificationsSuccess | LoadNotificationsFailure | ShowTutorialNotification
| DismissTutorialNotification | ResetTutorialNotification;
