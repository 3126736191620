import { OrganisationAction } from '@fairandsmart/types';
import { IndividualConfig } from 'ngx-toastr';
import { environment } from '@Env';

export const LANGUAGES = ['fr', 'en'];
export const ORG_PREFIX = 'org';
export const USERNAME_MIN_LENGTH = 4;
export const USERNAME_MAX_LENGTH = 30;
export const PASSPHRASE_MIN_LENGTH = 12;
export const API_ERROR_ALERT_CONFIG: Partial<IndividualConfig> = { disableTimeOut: 'timeOut' };
export const ERROR_ALERT_CONFIG: Partial<IndividualConfig> = { disableTimeOut: 'timeOut' };
export const WARNING_ALERT_CONFIG: Partial<IndividualConfig> = { disableTimeOut: 'timeOut' };
export const INFO_ALERT_CONFIG: Partial<IndividualConfig> = { disableTimeOut: 'timeOut' };
export const REQUEST_ATTACHMENT_SIZE_LIMIT = 1000000000; // 1Go
export const WS_RECONNECTION_TIMEOUT = 10000; // 10s
export const ALIAS_PATTERN: RegExp = /^[a-z0-9-.]+$/;
export const PREFERENCE_KEY: RegExp = /^[a-z0-9-.]+$/;
// tslint:disable-next-line
export const EMAIL_REGEXP = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

export const ROLES_CONFIG = {
  CM: {
    masterAction: OrganisationAction.CM_SECTION,
    actions: [
      OrganisationAction.CM_OPERATE,
      OrganisationAction.CM_EXPORT,
    ],
  },
  CM_MODEL: {
    masterAction: OrganisationAction.CM_MODEL_SECTION,
    actions: [
      OrganisationAction.CM_MODEL_CREATE,
      OrganisationAction.CM_MODEL_ACTIVATE,
    ],
  },
  CONSENT: {
    masterAction: OrganisationAction.CONSENT_SECTION,
    linkedActions: [OrganisationAction.CONSENT_SEARCH],
    actions: [
      OrganisationAction.CONSENT_EXPORT,
      OrganisationAction.CONSENT_REQUEST_EVALUATE,
    ],
  },
  CONSENT_MODEL: {
    masterAction: OrganisationAction.CONSENT_MODEL_SECTION,
    actions: [
      OrganisationAction.CONSENT_MODEL_CREATE,
      OrganisationAction.CONSENT_MODEL_ACTIVATE,
      OrganisationAction.CONSENT_SERIAL_GENERATE,
    ],
  },
  CONSENT_EMAIL_MODEL: {
    masterAction: OrganisationAction.CONSENT_EMAIL_MODEL_SECTION,
    actions: [OrganisationAction.CONSENT_EMAIL_MODEL_CREATE],
  },
  TREATMENT: {
    masterAction: OrganisationAction.TREATMENT_SECTION,
    actions: [OrganisationAction.TREATMENT_CREATE],
  },
  PREFERENCES: {
    masterAction: OrganisationAction.PREFERENCE_TYPE_SECTION,
    actions: [OrganisationAction.PREFERENCE_TYPE_CREATE],
  },
  COOKIES: {
    masterAction: OrganisationAction.COOKIES_CONFIG_SECTION,
    actions: [OrganisationAction.COOKIES_CONFIG_CREATE],
  },
  CONFIGURATION: {
    masterAction: OrganisationAction.CONFIG_SECTION,
    actions: [
      OrganisationAction.CONFIG_INFO_MANAGE,
      OrganisationAction.CONFIG_VISIBILITY_MANAGE,
      OrganisationAction.EVENTS_SEARCH,
    ],
  },
  MEMBER: {
    masterAction: OrganisationAction.CONFIG_MEMBER_SECTION,
    actions: [OrganisationAction.CONFIG_MEMBER_MANAGE],
  },
  ROLE: {
    masterAction: OrganisationAction.CONFIG_ROLE_SECTION,
    actions: [OrganisationAction.CONFIG_ROLE_MANAGE],
  },
  REQUEST: {
    masterAction: OrganisationAction.REQUEST_SECTION,
    actions: [
      OrganisationAction.REQUEST_CREATE,
      OrganisationAction.REQUEST_OPEN,
      OrganisationAction.REQUEST_POST,
      OrganisationAction.REQUEST_ANNOTATE,
      OrganisationAction.REQUEST_CLOSE,
      OrganisationAction.REQUEST_DELETE,
      OrganisationAction.REQUEST_PURGE,
    ],
  },
  REQUEST_RESPONSE_TEMPLATE: {
    masterAction: OrganisationAction.REQUEST_RESPONSE_TEMPLATE_SECTION,
    actions: [
      OrganisationAction.REQUEST_RESPONSE_TEMPLATE_CREATE,
    ],
  },
  REQUEST_CONFIGURATION: {
    masterAction: OrganisationAction.REQUEST_CONFIGURATION_SECTION,
    actions: [
      OrganisationAction.CONFIG_REQUEST_WHITELIST,
      OrganisationAction.CONFIG_REGISTRY_MANAGE,
      OrganisationAction.CONFIG_PARAMETERS_MANAGE,
    ],
  },
  CONNECTION: {
    masterAction: OrganisationAction.CONNECTION_SECTION,
    actions: [
      OrganisationAction.DATA_CONNECTION_MANAGE,
    ],
  },
  MARKETING: {
    masterAction: OrganisationAction.MARKETING_SECTION,
    actions: [ // TODO check actions
      OrganisationAction.CONSENT_MODEL_CREATE,
      OrganisationAction.CONSENT_MODEL_ACTIVATE,
      OrganisationAction.CONSENT_SERIAL_GENERATE,
    ],
  },
  CONNECTORS: {
    masterAction: OrganisationAction.CONNECTORS_SECTION,
    actions: [
      OrganisationAction.CONNECTORS_MANAGE,
    ],
  },
};

export interface Tutorial {
  url: string;
  description: { [key: string]: string };
  image: string;
}

export const TUTORIALS: { [key: string]: Tutorial[] } = {
  requests: [
    {
      url: `${environment.confluenceUrl}/spaces/BDC/pages/158498981#Cr%C3%A9erunedemandeRGPDdansRightRequests-manual_creation`,
      description: {
        fr: 'Création manuelle d\'une requête',
        en: 'Manual request creation',
      },
      image: 'url("assets/images/tutorial-request-manual-creation.png")',
    },
    {
      url: `${environment.confluenceUrl}/spaces/BDC/pages/158498981#Cr%C3%A9erunedemandeRGPDdansRightRequests-import`,
      description: {
        fr: 'Import d\'une requête et réponse',
        en: 'Request import and answer',
      },
      image: 'url("assets/images/tutorial-request-import.png")',
    },
  ],
};
