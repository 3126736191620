<mat-form-field [appearance]="appearance">
  <mat-label>{{label | translate}}</mat-label>
  <input matInput #inputElement [matAutocomplete]="auto" [formControl]="autoCompleteInput" [required]="required">
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="getName" (optionSelected)="selected($event)">
    <mat-option *ngIf="!required" [value]="null" class="entry-option none-option">
      <div class="entry-name">
        <span>- {{'RIGHT_CONSENTS.CONFIGURATION.LIBRARY.NONE' | translate }} -</span>
      </div>
    </mat-option>
    <mat-option *ngFor="let entry of filteredEntries$ | async" [value]="entry" class="entry-option">
      <div class="entry-name">
        <span>{{entry.name}}</span>
        <mat-icon class="pending" matTooltip="{{'RIGHT_CONSENTS.CONFIGURATION.LIBRARY.PENDING_HAS_DRAFT' | translate }}" *ngIf="hasDraft(entry)"></mat-icon>
      </div>
      <small>{{entry.description}}</small>
      <div class="entry-details">
        <small>{{entry.key}}</small>
        <div class="languages-entry">
          <span class="language" *ngFor="let lang of entry.availableLanguages">{{lang}}</span>
        </div>
      </div>
    </mat-option>
    <mat-option disabled class="error-option" *ngIf="!entries?.length">{{noEntryMessage | translate }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
