import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TruncUUIDPipe } from './truncUUID.pipe';
import { RegistryViewPipe } from './registry-view.pipe';
import { BytesPipe } from './bytes.pipe';
import { I18nFormLabelPipe } from './i18n-form-label.pipe';
import { SecondsToDatePipe } from './secondsToDate.pipe';
import { TrustResourceUrlPipe } from './trustResourceUrl';
import { RegistryEntryFieldPipe } from './registry-entry-field.pipe';
import { HumanReadableQueryPipe } from './human-readable-query.pipe';
import { I18nMapPipe } from './i18n-map-pipe';
import { ReferenceToNamePipe } from './reference-to-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [
    TruncUUIDPipe,
    RegistryViewPipe,
    RegistryEntryFieldPipe,
    BytesPipe,
    I18nFormLabelPipe,
    I18nMapPipe,
    SecondsToDatePipe,
    TrustResourceUrlPipe,
    HumanReadableQueryPipe,
    ReferenceToNamePipe,
  ],
  exports: [
    TruncUUIDPipe,
    RegistryViewPipe,
    RegistryEntryFieldPipe,
    BytesPipe,
    I18nFormLabelPipe,
    I18nMapPipe,
    SecondsToDatePipe,
    TrustResourceUrlPipe,
    HumanReadableQueryPipe,
    ReferenceToNamePipe,
  ],
  providers: [],
})
export class FsOrgPipesModule {
}
