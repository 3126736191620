import { Injectable } from '@angular/core';
import {
  from, Observable, of, Subject,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FsClientData, FsCryptService } from '@fairandsmart/angular';
import {
  DataCreationOptions,
  DataCreationOptionsDeciphered,
  DataReadOptions,
  DataUpdateOptions,
  DataUpdateOptionsDeciphered,
  DataUpdateOptionsEntity,
  DecipheredData,
  DecryptionFormat,
  FsData,
  SessionKey,
} from '@fairandsmart/types';
import { DataCreationOptionsEntity } from '@fairandsmart/types/lib/models/data';
import { environment } from '@Env';
import { AuthService } from '@Services/auth.service';
import { WorkerMethodRequestDto, WorkerMethodResponseDto, WorkerResponse } from './data.worker.types';

@Injectable({ providedIn: 'root' })
export class BackgroundService {
  worker: Worker;

  useFallbacks: boolean;

  listener$ = new Subject<WorkerResponse<any>>();

  constructor(
    private cryptService: FsCryptService,
    private clientData: FsClientData,
    private authService: AuthService,
  ) {
  }

  init(): Observable<boolean> {
    if (typeof Worker !== 'undefined') {
      // Create a new
      this.worker = new Worker(new URL('./data-worker.worker', import.meta.url), { type: 'module' });
      this.startListener();
      const reqId = this.postMessage('init', { config: { apiUrl: environment.apiUrl, catalogUrl: environment.catalogUrl } });
      return this.waitFor<'init'>(reqId).pipe(map((res) => {
        if (res.data.success) {
          this.useFallbacks = false;
        }
        return res.data.success;
      }));
    }
    // Web Workers are not supported in this environment. Return false if you wish to deactivate the fallback mecanisms
    console.info('Webworkers not available, using fallbacks');
    this.useFallbacks = true;
    return of(this.useFallbacks);
  }

  waitFor<Method extends keyof WorkerMethodRequestDto>(id: number): Observable<WorkerMethodResponseDto[Method]> {
    return new Observable((obs) => {
      this.listener$.pipe(
        filter((message) => message.id === id),
      ).subscribe((message) => {
        if (message.error) {
          obs.error(message);
        } else {
          obs.next(message);
          if (!message.isProgressNotification) {
            obs.complete();
          }
        }
      });
    });
  }

  loadCrypto(options: WorkerMethodRequestDto['loadCrypto']): Observable<WorkerMethodResponseDto['loadCrypto']> {
    if (this.useFallbacks) {
      this.cryptService.setKeysFromBiKey(options.bikey);
      return from(this.cryptService.releasePrivateKey(options.passphrase)).pipe(map(() => ({ data: { granted: true } } as any)));
    }
    const reqId = this.postMessage('loadCrypto', options);
    return this.waitFor<'loadCrypto'>(reqId);
  }

  objectToUInt8Array(obj: any): Uint8Array {
    const array = [];
    Object.keys(obj).forEach((key) => {
      array[parseInt(key, 10)] = obj[key];
    });
    return Uint8Array.from(array);
  }

  getData<T>(dataId: string, readOptions: DataReadOptions): Observable<DecipheredData<T>> {
    if (this.useFallbacks) {
      return this.clientData.get<T>(dataId, readOptions);
    }
    const reqId = this.postMessage('decipherData', { dataId, readOptions, params: { bearer: this.authService.keycloak.getKeycloakInstance().token } });
    return this.waitFor<'decipherData'>(reqId).pipe(map((response) => {
      if (readOptions.outputFormat === DecryptionFormat.RAW) {
        response.data.clearContent[0] = this.objectToUInt8Array(response.data.clearContent[0]);
      }
      return new DecipheredData<T>((response.data as any).entity, response.data.clearContent);
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createData<T>(createOptions: DataCreationOptionsDeciphered): Observable<DecipheredData<T>>;
  createData(createOptions: DataCreationOptionsEntity): Observable<FsData>;
  createData<T = any>(createOptions: DataCreationOptions): Observable<FsData | DecipheredData<T>> {
    if (this.useFallbacks || createOptions.content instanceof ArrayBuffer) {
      return this.clientData.create<T>(createOptions as any);
    }
    const reqId = this.postMessage('createData', { createOptions, params: { bearer: this.authService.keycloak.getKeycloakInstance().token } });
    return this.waitFor<'createData'>(reqId).pipe(map((response) => {
      if (Object.prototype.hasOwnProperty.call(response.data, 'clearContent')) {
        return new DecipheredData<T>((response.data as any).entity, (response.data as any).clearContent);
      }
      return response.data;
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateData<T>(updateOptions: DataUpdateOptionsDeciphered): Observable<DecipheredData<T>>;
  updateData(updateOptions: DataUpdateOptionsEntity): Observable<FsData>;
  updateData<T = any>(updateOptions: DataUpdateOptions): Observable<any> {
    if (this.useFallbacks || updateOptions.content instanceof ArrayBuffer) {
      return this.clientData.update<T>(updateOptions as any);
    }
    const reqId = this.postMessage('updateData', { updateOptions, params: { bearer: this.authService.keycloak.getKeycloakInstance().token } });
    return this.waitFor<'updateData'>(reqId).pipe(map((response) => {
      if (Object.prototype.hasOwnProperty.call(response.data, 'clearContent')) {
        return new DecipheredData<T>((response.data as any).entity, (response.data as any).clearContent);
      }
      return response.data;
    }));
  }

  generateCSK(): Observable<SessionKey> {
    if (this.useFallbacks) {
      return from(this.cryptService.generateCSK());
    }
    const reqId = this.postMessage('generateCSK', undefined);
    return this.waitFor<'generateCSK'>(reqId).pipe(map((response) => response.data));
  }

  purgeCrypt() {
    if (!this.useFallbacks) {
      this.postMessage('purgeCrypt', undefined);
    }
  }

  private postMessage<Method extends keyof WorkerMethodRequestDto>(method: Method, data: WorkerMethodRequestDto[Method]): number {
    const id = Math.round(Math.random() * 10000);
    const request = JSON.stringify({ method, data, id });
    this.worker.postMessage(request);
    return id;
  }

  private startListener() {
    this.worker.onmessage = ({ data }) => {
      try {
        const message: WorkerResponse<any> = JSON.parse(data);
        this.listener$.next(message);
      } catch (err) {
        console.error(err);
        this.listener$.next({ error: true, data: undefined, id: undefined });
      }
    };
  }
}
