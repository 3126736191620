import { Injectable } from '@angular/core';
import { FsCryptService, FsDataService } from '@fairandsmart/angular';
import {
  AbstractEntity,
  DataCreationOptions,
  DataFilter,
  DataFormat,
  DataStatus,
  DataUpdateOptions,
  DecipheredData,
  EntityCollection,
  EntityIdentifierHelper,
  EntityName,
  EntityReference,
  OrganisationType,
  SessionKey,
} from '@fairandsmart/types';
import FlexSearch from 'flexsearch';
import * as _ from 'lodash';
import {
  BehaviorSubject, EMPTY, from, Observable, of, ReplaySubject, Subject, Subscription,
} from 'rxjs';
import {
  catchError, filter, finalize, first, map, mergeMap, shareReplay, take, tap,
} from 'rxjs/operators';
import { OrgaService } from '@Services/orga.service';
import { AlertService } from '@Services/alert.service';
import { SecurityService } from '@Services/security.service';
import { FlexSearchIndex } from './flex-search';
import { IndexingQueue } from './indexing-queue';
import { ACL } from '../components/organisation/organisation-acl';
import { BackgroundService } from '../workers/worker.service';

export interface FlexIndexDocument<T = any> {
  id: string; // entityId
  eN: EntityName; // entityName
  mD: number; // modificationDate
  ref: string; // reference
  cRef?: string; // connection reference
  rRef?: string; // request reference
  dF?: string; // data format
  c?: T; // content
  // [key: string]: any;
}

export enum FlexIndexEventType {
  INDEXATION_START = 'indexation-start',
  INDEXED = 'indexed',
  ALREADY_INDEXED = 'already-indexed',
  INDEXATION_ERROR = 'indexation-error',
  UNHANDLED_FORMAT = 'unhandled_format',
}

export interface FlexIndexEvent {
  type: FlexIndexEventType;
  entityId: string;
  entityName: EntityName;
  reference: EntityReference;
  format?: string;
  message?: string;
  doc?: FlexIndexDocument;
}

export interface IndexedEvent extends FlexIndexEvent {
  type: FlexIndexEventType.INDEXED | FlexIndexEventType.ALREADY_INDEXED;
  doc: FlexIndexDocument;
}

export function isIndexedEvent(event: FlexIndexEvent): event is FlexIndexEvent {
  return event.type === FlexIndexEventType.ALREADY_INDEXED || event.type === FlexIndexEventType.INDEXED;
}

export type FlexIndexableEntity = AbstractEntity & { modificationDate: number, reference: EntityReference, format?: string, tags?: string[] };

export interface FlexIndexationHandler<T extends FlexIndexableEntity = FlexIndexableEntity> {
  isHandling(entity: FlexIndexableEntity): boolean;

  handle(entity: T): Observable<FlexIndexDocument>;
}

export interface FlexIndexationStatus {
  id: string;
  last: number;
}

export interface FlexIndexationProgress {
  size: number;
  done: number;
  error: number;
}

@Injectable()
export class FlexSearchService {
  private index: FlexSearchIndex<FlexIndexDocument | FlexIndexationStatus>;

  private indexChanged: boolean;

  private indexData: DecipheredData<string>;

  private indexationProcess: Subscription;

  private queue: IndexingQueue;

  private eventsSource: Subject<FlexIndexEvent>;

  public events: Observable<FlexIndexEvent>;

  private progressSource: Subject<number> = new Subject();

  public progress$: Observable<number> = this.progressSource.asObservable();

  private readonly progress: { [id: string]: FlexIndexationProgress };

  private overallProgress: number;

  private handlers: FlexIndexationHandler[];

  private readySource: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public ready: Observable<boolean> = this.readySource.pipe(shareReplay(1));

  constructor(
    private orgaService: OrgaService,
    private cryptService: FsCryptService,
    private fsDataService: FsDataService,
    private alertService: AlertService,
    private securityService: SecurityService,
    private backgroundService: BackgroundService,
  ) {
    this.ready.subscribe(); // Initial subscription to allow other observers to replay the last value
    this.handlers = [];
    this.progress = {};
  }

  init(): Observable<void> {
    if (this.orgaService.type === OrganisationType.VIRTUAL || !this.securityService.isOneActionAllowedForUser(ACL.requests.actions)) {
      this.readySource.next(false);
      return of(null);
    }
    this.indexChanged = false;
    this.queue = new IndexingQueue();
    this.eventsSource = new Subject<FlexIndexEvent>();
    this.events = this.eventsSource.asObservable();
    return this.getIndexData().pipe(
      mergeMap((indexData) => {
        if (indexData == null) {
          return this.createIndexData();
        }
        this.loadIndex(indexData);
        return of(indexData);
      }),
      map((indexData) => {
        this.indexData = indexData;
        this.startIndexationProcess();
        this.readySource.next(true);
      }),
      first(),
      catchError((err) => {
        console.error('[FLEXSEARCH ERROR]', err);
        this.alertService.error('COMMON.ERRORS.DATA_INDEX_UNAVAILABLE');
        return of(null);
      }),
    );
  }

  registerHandler(handler: FlexIndexationHandler): void {
    this.handlers.push(handler);
  }

  setProgress(id: string, progress: FlexIndexationProgress): void {
    this.progress[id] = progress;
    const tmp = { size: 0, done: 0, error: 0 };
    _.forEach(this.progress, (p: FlexIndexationProgress) => {
      tmp.size += p.size;
      tmp.done += p.done;
      tmp.error += p.error;
    });
    this.overallProgress = Math.floor(((tmp.done + tmp.error) / tmp.size) * 100) || null;
    this.progressSource.next(this.overallProgress);
  }

  private addDocument(doc: FlexIndexDocument | FlexIndexationStatus): void {
    this.index.add(doc);
    this.indexChanged = true;
  }

  getDocument<D = FlexIndexDocument>(id: string): D {
    return this.index.find<D>(id);
  }

  removeDocumentsForReference(ref: string): void {
    const documents = this.searchForReference(ref);
    const doc: FlexIndexDocument = this.index.find(EntityIdentifierHelper.extractId(ref));
    if (doc != null) {
      documents.push(doc);
    }
    if (documents.length > 0) {
      this.index.remove(documents as any);
      this.indexChanged = true;
      this.saveIndex();
    }
  }

  addStatusDocument(status: FlexIndexationStatus): void {
    this.addDocument(status);
  }

  search(content: string, options: { entityName?: EntityName, format?: string }): FlexIndexDocument[] {
    const searchOptions: any[] = [{
      field: 'c',
      query: content,
      bool: 'and',
    }];
    if (options.entityName) {
      searchOptions.push({
        field: 'eN',
        query: options.entityName,
        bool: 'and',
      });
    }
    if (options.format) {
      searchOptions.push({
        field: 'dF',
        query: options.format,
        bool: 'and',
      });
    }
    return (this.index.search(searchOptions as any) as any).filter((r) => r != null);
  }

  searchForReference(
    reference: EntityReference,
    options: { referenceType?: 'ref' | 'rRef' | 'cRef', entityName?: EntityName, format?: string } = {},
  )
    : FlexIndexDocument[] {
    const searchOptions: any[] = [{
      field: options.referenceType || 'ref',
      query: reference,
      bool: 'and',
    }];
    if (options.entityName) {
      searchOptions.push({
        field: 'eN',
        query: options.entityName,
        bool: 'and',
      });
    }
    if (options.format) {
      searchOptions.push({
        field: 'dF',
        query: options.format,
        bool: 'and',
      });
    }
    return (this.index.search(searchOptions as any) as any).filter((r) => r != null);
  }

  public queueEntities(entities: FlexIndexableEntity[], highPriority: boolean, observe: false): null;

  public queueEntities(entities: FlexIndexableEntity[], highPriority: boolean, observe: true): Observable<FlexIndexEvent>;

  public queueEntities(entities: FlexIndexableEntity[], highPriority: boolean, observe: boolean): Observable<FlexIndexEvent> | null {
    if (observe) {
      const subject: ReplaySubject<FlexIndexEvent> = new ReplaySubject<FlexIndexEvent>();
      const errors: FlexIndexEvent[] = [];
      this.eventsSource.pipe(
        filter((event: FlexIndexEvent) => event.type === FlexIndexEventType.INDEXED
          || event.type === FlexIndexEventType.ALREADY_INDEXED
          || event.type === FlexIndexEventType.INDEXATION_ERROR),
        filter((event) => entities.findIndex((d) => d.entityId === event.entityId) !== -1),
        tap((event: FlexIndexEvent) => {
          subject.next(event);
          if (event.type === FlexIndexEventType.INDEXATION_ERROR) {
            errors.push(event);
          }
        }),
        take(entities.length),
        finalize(() => {
          if (errors.length > 0) {
            console.warn('[FLEXSEARCH] Indexation error for: ', errors.map((d) => d.entityId));
          }
          subject.complete();
        }),
      ).subscribe();
      this.queue.push(entities, highPriority);
      return subject.asObservable();
    }
    this.queue.push(entities, highPriority);
    return null;
  }

  private fireEvent(entity: FlexIndexableEntity, type: FlexIndexEventType.INDEXATION_START | FlexIndexEventType.UNHANDLED_FORMAT | FlexIndexEventType.INDEXATION_ERROR): void;

  private fireEvent(entity: FlexIndexableEntity, type: FlexIndexEventType.INDEXED | FlexIndexEventType.ALREADY_INDEXED, doc: FlexIndexDocument): void;

  private fireEvent(entity: FlexIndexableEntity, type: FlexIndexEventType, doc?: FlexIndexDocument): void {
    const event: FlexIndexEvent = {
      type,
      entityId: entity.entityId,
      entityName: entity.entityName,
      reference: entity.reference,
    };
    if (entity.entityName === EntityName.DATA) {
      event.format = entity.format;
    }
    if (doc != null) {
      event.doc = doc;
    }
    this.eventsSource.next(event);
  }

  private startIndexationProcess(): void {
    if (this.indexationProcess == null || this.indexationProcess.closed) {
      this.indexationProcess = this.queue.observe().pipe(
        tap((entity: FlexIndexableEntity) => this.fireEvent(entity, FlexIndexEventType.INDEXATION_START)),
        mergeMap((entity: FlexIndexableEntity) => {
          const indexedDoc: FlexIndexDocument = this.getDocument(entity.entityId);
          if (indexedDoc == null || indexedDoc.mD !== entity.modificationDate) {
            const handler = this.handlers.find((h) => h.isHandling(entity));
            if (handler == null) {
              this.fireEvent(entity, FlexIndexEventType.UNHANDLED_FORMAT);
              return EMPTY;
            }
            return handler.handle(entity).pipe(
              tap((doc: FlexIndexDocument) => {
                this.addDocument(doc);
                this.fireEvent(entity, FlexIndexEventType.INDEXED, doc);
              }),
              catchError((error: Error) => {
                console.error('[FLEXSEARCH] Indexation error', error.message);
                this.fireEvent(entity, FlexIndexEventType.INDEXATION_ERROR);
                return of(null);
              }),
            );
          }
          this.fireEvent(entity, FlexIndexEventType.ALREADY_INDEXED, indexedDoc);
          return of(null);
        }),
      ).subscribe(() => {
        this.queue.taskDone();
        if (this.queue.isEmpty()) {
          this.saveIndex();
        } else {
          this.queue.processNext();
        }
      });
      // Process next if there are already some element in the queue
      this.queue.processNext();
    }
  }

  private createIndex(): FlexSearchIndex<FlexIndexDocument> {
    return FlexSearch.create<FlexIndexDocument>({
      doc: {
        id: 'id',
        field: {
          eN: {
            tokenize: (x) => [x],
          },
          ref: {
            tokenize: (x) => [x],
          },
          cRef: {
            tokenize: (x) => [x],
          },
          rRef: {
            tokenize: (x) => [x],
          },
          dF: {
            tokenize: (x) => [x.split(':')[0], x],
          },
          c: {
            tokenize: 'forward',
            encode: 'simple',
          },
        },
      },
    }) as FlexSearchIndex<FlexIndexDocument>;
  }

  private loadIndex(indexData: DecipheredData): void {
    const index = this.createIndex();
    index.import(JSON.parse(indexData.clearContent));
    this.index = index;
  }

  private saveIndex(): void {
    if (!this.indexChanged) {
      return;
    }
    if (!this.queue.isLocked()) {
      this.queue.lock();
      this.updateIndexData().subscribe(() => {
        this.indexChanged = false;
      }, (reason) => {
        console.error('[FLEXSEARCH] Cannot save index', reason);
      }, () => {
        this.queue.unlock();
        this.queue.processNext();
      });
    }
  }

  private getIndexData(): Observable<DecipheredData> {
    const dataFilter: DataFilter = {
      reference: this.orgaService.reference,
      format: DataFormat.INDEX_V,
      status: DataStatus.ACTIVE,
    };
    return this.fsDataService.search(dataFilter).pipe(
      mergeMap((response: EntityCollection<DecipheredData>) => {
        if (response?.values.length > 0) {
          if (response.values.length > 1) {
            console.warn(`[FLEXSEARCH] Multiple index found for organisation ${this.orgaService.id}`);
          }
          return this.backgroundService.getData(response.values[0].id, { ciphererId: this.orgaService.id });
        }
        return of(null);
      }),
    );
  }

  private createIndexData(): Observable<DecipheredData> {
    return from(this.cryptService.generateCSK()).pipe(
      mergeMap((sessionKey: SessionKey) => {
        this.index = this.createIndex();
        const options: DataCreationOptions = {
          content: JSON.stringify(this.index.export()),
          format: DataFormat.INDEX_V,
          reference: this.orgaService.reference,
          sessionKey,
          endpoint: this.orgaService.reference,
          source: this.orgaService.reference,
          ciphererId: this.orgaService.id,
          wrapResponse: true,
        };
        return this.backgroundService.createData(options);
      }),
    );
  }

  private updateIndexData(): Observable<DecipheredData> {
    const options: DataUpdateOptions = {
      dataId: this.indexData.id,
      content: JSON.stringify(this.index.export()),
      format: DataFormat.INDEX_V,
      endpoint: this.orgaService.reference,
      ciphererId: this.orgaService.id,
      sessionKeys: this.indexData.getKeys(1),
      wrapResponse: true,
    };
    return this.backgroundService.updateData(options);
  }
}
