import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncUUID',
})
export class TruncUUIDPipe implements PipeTransform {
  transform(value: string): any {
    return value.split('-')[0].toUpperCase();
  }
}
