import { KeycloakOptions } from 'keycloak-angular';
import { environment } from '@Env';

export const KEYCLOAK_CONFIG: KeycloakOptions = {
  config: {
    url: environment.authUrl,
    realm: environment.authRealm,
    clientId: environment.authClientId,
  },
  initOptions: {
    onLoad: 'check-sso',
    checkLoginIframe: false,
  },
  enableBearerInterceptor: true,
  bearerExcludedUrls: [
    '/assets',
  ],
};
