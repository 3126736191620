<div class="language-helper" [ngClass]="{'hidden': !showText}" *ngIf="!hidden && defaultLanguage">
  <img class="language-icon" [matTooltip]="'LANGUAGE_HELPER.SHOW_HIDE' | translate" alt="{{defaultLanguage}}"
       [src]="'assets/images/flags/' + defaultLanguage + '.svg'" (click)="showText = !showText">
  <div class="content" (click)="showText = true" [matTooltip]="showText ? ('LANGUAGE_HELPER.EXPLANATION' | translate) : null">
    <ng-container *ngIf="showText; else hiddenText">
      <ng-container *ngIf="arrayValue.length > 0; else singleValue">
        <ng-container *ngFor="let value of arrayValue; let index = index">
          {{i18nPrefix ? (i18nPrefix + value | translate) : value}}<ng-container *ngIf="index < arrayValue.length - 1">,</ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #singleValue>
      <ng-container *ngIf="isBoolean; else singleText">
        {{'LANGUAGE_HELPER.CHECKBOX.' + textValue | translate}}
      </ng-container>
    </ng-template>
    <ng-template #singleText>
      <ng-container *ngIf="textValue; else emptyText">
        {{i18nPrefix ? (i18nPrefix + textValue | translate) : textValue}}
      </ng-container>
    </ng-template>
    <ng-template #emptyText>-</ng-template>
    <ng-template #hiddenText>{{'LANGUAGE_HELPER.SHOW' | translate}}</ng-template>
  </div>
</div>
