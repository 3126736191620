import { Component, Inject } from '@angular/core';
import {
  AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FsCryptService, FsOrganisationService, ProfileService } from '@fairandsmart/angular';
import { BiKey } from '@fairandsmart/types';
import * as _ from 'lodash';
import { from, Observable, of } from 'rxjs';
import {
  catchError, delay, map, mergeMap, tap,
} from 'rxjs/operators';
import { AlertService } from '@Services/alert.service';
import { OrgaService } from '@Services/orga.service';
import { TranslateService } from '@ngx-translate/core';
import { PASSPHRASE_MIN_LENGTH } from '../constants';
import { BackgroundService } from '../../../workers/worker.service';

export interface FairCodeDialogComponentData {
  id: string;
  name: string;
  alias: string;
  random?: string;
  change?: boolean;
  orgaService: OrgaService;
  bikey?: BiKey;
}

export enum FairCodeDialogResult {
  RELEASED,
  CANCELLED,
  FAILED,
}

export function isTemporaryPassphrase(passphrase: string, organisationId: string): boolean {
  return passphrase.startsWith(organisationId.slice(0, 4));
}

export function generateTemporaryPassphrase(organisationId: string): string {
  if (organisationId == null) {
    return null;
  }
  const crypto = window.crypto || (window as any).msCrypto;
  const random1 = crypto.getRandomValues(new Uint32Array(1))[0] * 10;
  const random2 = crypto.getRandomValues(new Uint32Array(1))[0] * 10;
  return organisationId.slice(0, 4) + random1.toString(36).slice(0, 6).padStart(6, '0') + random2.toString(36).slice(0, 6).padStart(6, '0');
}

export function fairCodeStrengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value == null || control.value.length === 0) {
      return { required: true };
    }
    const hasLength = control.value.length >= minLength;
    const hasUpperCase = /[A-Z]/.test(_.deburr(control.value));
    const hasLowerCase = /[a-z]/.test(_.deburr(control.value));
    const hasNumbers = /\d/.test(control.value);
    const hasNonAlphas = /[\W_]/.test(control.value);
    const valid = hasLength && hasUpperCase && hasLowerCase && hasNumbers && hasNonAlphas;
    return valid ? null : {
      hasUpperCase,
      hasLowerCase,
      hasNumbers,
      hasNonAlphas,
      hasLength,
      fairCodeStrength: true,
    };
  };
}

@Component({
  selector: 'fs-fair-code-dialog',
  templateUrl: './fair-code-dialog.component.html',
  styleUrls: ['./fair-code-dialog.component.scss'],
})
export class FairCodeDialogComponent {
  public fairCodeControl: UntypedFormControl;

  public updatePassphraseForm: UntypedFormGroup;

  public recoveryForm: UntypedFormGroup;

  public readonly PASSPHRASE_MIN_LENGTH = PASSPHRASE_MIN_LENGTH;

  private readonly FAIL_TIMEOUT = 5000;

  private fail = 0;

  constructor(
    private dialog: MatDialogRef<FairCodeDialogComponent, FairCodeDialogResult>,
    private alert: AlertService,
    private crypt: FsCryptService,
    private profileService: ProfileService,
    private fsOrganisationService: FsOrganisationService,
    @Inject(MAT_DIALOG_DATA) public data: FairCodeDialogComponentData,
    private worker: BackgroundService,
    public translate: TranslateService,
  ) {
    this.fairCodeControl = new UntypedFormControl('', [Validators.required]);
    data.random = `${Math.floor(Math.random() * 1000000000000)}`.slice(0, 6);
  }

  release(): void {
    if (this.fairCodeControl.valid) {
      this.checkPassphrase().subscribe((released) => {
        if (released) {
          this.translate.use(this.profileService.getLanguage());
          this.dialog.close(FairCodeDialogResult.RELEASED);
        }
      });
    }
  }

  cancel(): void {
    if (this.data.change && this.fail > 0) {
      this.data.orgaService.switchOrganisation().then(() => {
        this.alert.info('FAIR_CODE.SECURITY_LOCK');
      });
      this.dialog.close(FairCodeDialogResult.FAILED);
    } else {
      this.dialog.close(FairCodeDialogResult.CANCELLED);
    }
  }

  private checkPassphrase(): Observable<boolean> {
    this.fairCodeControl.markAsPending();
    this.fairCodeControl.disable();

    /** Keep this portion of code to retain crypt features in the main thread */
    this.crypt.setKeysFromBiKey(this.data.bikey);
    this.crypt.releasePrivateKey(this.fairCodeControl.value).catch(() => console.error('Local bikey not released'));
    /** All crypto heavy method should be handled by the WebWorker, but light tasks should be handled by CryptService */

    return this.worker.loadCrypto({ orgaId: this.data.id, bikey: this.data.bikey, passphrase: this.fairCodeControl.value }).pipe(
      map(() => {
        this.fail = 0;
        // If passphrase is temporary show update form
        if (this.data.change || isTemporaryPassphrase(this.fairCodeControl.value, this.data.id)) {
          this.initUpdatePassphraseForm();
          return false;
        }
        return true;
      }),
      catchError((error) => {
        this.fail += 1;
        return of(false).pipe(
          delay(this.fail > 5 ? this.FAIL_TIMEOUT : 0),
          tap(() => {
            console.error(`Could not release private key; ${error}`);
            this.fairCodeControl.enable();
            this.fairCodeControl.setErrors({ wrongPassphrase: true });
          }),
        );
      }),
    );
  }

  private initUpdatePassphraseForm(): void {
    this.updatePassphraseForm = new UntypedFormGroup({
      passphrase: new UntypedFormControl('', [
        fairCodeStrengthValidator(PASSPHRASE_MIN_LENGTH),
      ]),
      passphraseConfirmation: new UntypedFormControl('', [
        Validators.required,
      ]),
    });
  }

  updatePassphrase(): void {
    if (this.updatePassphraseForm.enabled) {
      if (this.updatePassphraseForm.errors) {
        this.updatePassphraseForm.setErrors(null);
      }
      if (this.updatePassphraseForm.valid) {
        this.updatePassphraseForm.disable();
        const newPassphrase: string = this.updatePassphraseForm.get('passphrase').value;
        if (newPassphrase !== this.updatePassphraseForm.get('passphraseConfirmation').value) {
          this.updatePassphraseForm.enable();
          this.updatePassphraseForm.get('passphraseConfirmation').setErrors({ notMatching: true });
          return;
        }
        if (newPassphrase === this.fairCodeControl.value) {
          this.updatePassphraseForm.enable();
          this.updatePassphraseForm.get('passphrase').setErrors({ notChanged: true });
          return;
        }

        from(this.crypt.wrapBiKeyWithPassphrase(newPassphrase)).pipe(
          mergeMap((biKey: BiKey) => this.fsOrganisationService.updateMemberBiKey(
            this.data.id,
            this.profileService.id,
            biKey,
          ).pipe(
            mergeMap(() => {
              this.crypt.setKeysFromBiKey(biKey);
              return from(this.crypt.releasePrivateKey(newPassphrase));
            }),
          )),
        ).subscribe(() => {
          if (this.data.change) {
            this.alert.success('FAIR_CODE.UPDATE_SUCCESS');
          }
          this.dialog.close(FairCodeDialogResult.RELEASED);
        }, (error) => {
          console.error('An unexpected error occurred during passphrase update', error);
          this.updatePassphraseForm.enable();
          this.updatePassphraseForm.setErrors({ updateFailed: true });
        });
      }
    }
  }

  private initRecoveryForm(): void {
    this.recoveryForm = new UntypedFormGroup({
      masterPassphrase: new UntypedFormControl('', [
        Validators.required,
      ]),
    });
  }

  startRecovery(): void {
    this.fairCodeControl.markAsPending();
    this.fairCodeControl.disable();
    this.fsOrganisationService.getBiKey(this.data.id).subscribe((biKey) => {
      this.crypt.setKeysFromBiKey(biKey);
      this.initRecoveryForm();
    });
  }

  recover(): void {
    this.checkMasterPassphrase().subscribe();
  }

  cancelRecovery(): void {
    this.recoveryForm = null;
    this.fsOrganisationService.getMemberBiKey(this.data.id, this.profileService.id).subscribe((biKey) => {
      this.crypt.setKeysFromBiKey(biKey);
      this.fairCodeControl.enable();
      this.fairCodeControl.markAsUntouched();
      this.fairCodeControl.markAsPristine();
    });
  }

  private checkMasterPassphrase(): Observable<boolean> {
    this.recoveryForm.markAsPending();
    this.recoveryForm.disable();
    return from(this.crypt.releasePrivateKey(this.recoveryForm.get('masterPassphrase').value)).pipe(
      map(() => {
        this.fail = 0;
        this.initUpdatePassphraseForm();
        return false;
      }),
      catchError((error) => {
        this.fail += 1;
        return of(false).pipe(
          delay(this.fail > 5 ? this.FAIL_TIMEOUT : 0),
          tap(() => {
            console.error(`Could not release private key; ${error}`);
            this.recoveryForm.get('masterPassphrase').enable();
            this.recoveryForm.get('masterPassphrase').setErrors({ wrongPassphrase: true });
          }),
        );
      }),
    );
  }
}
