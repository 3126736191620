import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileService } from '@fairandsmart/angular';
import { Profile } from '@fairandsmart/types';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '@Env';
import { AuthService } from '@Services/auth.service';
import { RightConsentsService } from '@Services/right-consents.service';
import { LANGUAGES } from '../constants';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';

@Component({
  selector: 'fs-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent {
  profile$: Observable<Profile>;

  readonly LANGUAGES = LANGUAGES;

  readonly CUSTOMIZATION = environment.customization;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog,
    public profileService: ProfileService,
    public translate: TranslateService,
    public rightConsentsService: RightConsentsService,
  ) {
    this.profile$ = this.profileService.change;
  }

  logout() {
    this.dialog.open(LogoutDialogComponent).afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        this.auth.keycloak.logout(window.location.origin);
        this.rightConsentsService.purge();
      }
    });
  }
}
