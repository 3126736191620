import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, zip } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

@Pipe({
  name: 'i18nFormLabel',
})
export class I18nFormLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(key: string, args: { lang: string, params?: any }): Observable<string> {
    return this.translate.onLangChange.pipe(
      startWith(''),
      mergeMap(() => zip(
        this.translate.get(key, args.params),
        this.translate.get(`LANGUAGES.${args.lang}`),
      )),
      map((value: [string, string]) => `${value[0]} (${value[1]})`),
    );
  }
}
