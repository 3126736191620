<mat-toolbar>
  <mat-toolbar-row>
    <img class="title-img" alt="Logo" [style]="CUSTOMIZATION.headerLogoStyle" [src]="CUSTOMIZATION.headerLogoUrl"
         [routerLink]="['/']">
    <span fxFlex></span>
    <button mat-icon-button [matMenuTriggerFor]="languagesMenu">
      <mat-icon>language</mat-icon>
    </button>
    <mat-menu #languagesMenu>
      <ng-template matMenuContent>
        <button mat-menu-item *ngFor="let language of LANGUAGES" [disabled]="language === translate.currentLang"
                (click)="translate.use(language)">
          {{'UI_LANGUAGES.' + language | translate}}
        </button>
      </ng-template>
    </mat-menu>
    <ng-container *ngIf="auth.isAuthenticated()">
      <ng-container *ngIf="profile$ | async as profile">
        <button class="profile-button" mat-button [matMenuTriggerFor]="menu">
          <img alt="user avatar" fsAvatar [profileId]="profile.userid"/>
          <span class="username">{{profile.username}} </span>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout()">
            {{'COMMON.BUTTONS.LOGOUT' | translate}}
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
