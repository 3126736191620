import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FsApiError } from '@fairandsmart/types';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogData } from '@Common/confirm-dialog/confirm-dialog.component';
import {
  API_ERROR_ALERT_CONFIG,
  ERROR_ALERT_CONFIG,
  INFO_ALERT_CONFIG,
  WARNING_ALERT_CONFIG,
} from '@Common/constants';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
  ) {
  }

  success(key: string, options?: Partial<IndividualConfig>, interpolateParams?: object): void {
    this.alert('toast-success', key, options, interpolateParams);
  }

  info(key: string, options?: Partial<IndividualConfig>, interpolateParams?: object): void {
    this.alert('toast-info', key, _.merge(INFO_ALERT_CONFIG, options), interpolateParams);
  }

  warn(key: string, options?: Partial<IndividualConfig>, interpolateParams?: object): void {
    this.alert('toast-warn', key, _.merge(WARNING_ALERT_CONFIG, options), interpolateParams);
  }

  error(key: string, error?: any, options?: Partial<IndividualConfig>, interpolateParams?: object): void {
    if (error) {
      console.error(error);
    }
    this.alert('toast-error', key == null ? 'COMMON.ERRORS.UNKNOWN' : key, _.merge(ERROR_ALERT_CONFIG, options), interpolateParams);
  }

  apiError(error: FsApiError, base: string | null, options?: Partial<IndividualConfig>, interpolateParams?: object): void {
    if (error) {
      console.error(error);
    }
    const overrideConfig: Partial<IndividualConfig> = _.merge(API_ERROR_ALERT_CONFIG, options);
    const message = base == null || error == null || error.code == null ? 'COMMON.ERRORS.UNKNOWN' : `${base}.${error.code}`;
    this.translate.get(message, interpolateParams).pipe(
      mergeMap((trans1: string) => {
        if (message === trans1) {
          const DEFAULT_KEY = `${base}.DEFAULT`;
          return this.translate.get(DEFAULT_KEY).pipe(
            mergeMap((trans2) => {
              if (trans2 === DEFAULT_KEY) {
                return this.translate.get('COMMON.ERRORS.UNKNOWN');
              }
              return of(trans2);
            }),
          );
        }
        return of(trans1);
      }),
    ).subscribe((translation: string) => {
      this.toastrService.show(translation, '', overrideConfig, 'toast-error');
    });
  }

  private alert(type: AlertType, key: string, options?: Partial<IndividualConfig>, interpolateParams?: object): void {
    this.translate.get(key, interpolateParams).subscribe((message: string) => {
      this.toastrService.show(message, '', options, type);
    });
  }

  promptConfirmDialog(config: MatDialogConfig<ConfirmDialogData> & { data: ConfirmDialogData })
    : MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, config);
  }
}

export type AlertType = 'toast-info' | 'toast-success' | 'toast-error' | 'toast-warn';
