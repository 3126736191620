<ng-container *ngIf="updatePassphraseForm == null; else updateForm">
  <ng-container *ngIf="recoveryForm == null; else recovery">
    <h2 mat-dialog-title>{{'FAIR_CODE.PASSPHRASE' | translate}}</h2>
    <form (ngSubmit)="release()">
      <div mat-dialog-content>
        <p>{{'FAIR_CODE.ASK_PASSPHRASE' | translate:{name: data.name} }}</p>
        <input hidden name="fs-organisation-alias" [value]="data.alias"/>
        <mat-form-field appearance="fill">
          <mat-label>{{'FAIR_CODE.PASSPHRASE' | translate}}</mat-label>
          <input matInput type="password" tabindex="1" [formControl]="fairCodeControl" [id]="'fs-fair-code-' + data.id"
                 [name]="'fs-fair-code-' + data.id" autocomplete="current-password" cdkFocusInitial>
          <mat-error *ngIf="fairCodeControl.hasError('required')">{{"COMMON.ERRORS.REQUIRED" | translate}}</mat-error>
          <mat-error *ngIf="fairCodeControl.hasError('wrongPassphrase')">
            {{"FAIR_CODE.ERRORS.WRONG_PASSPHRASE" | translate}}
          </mat-error>
        </mat-form-field>
        <p class="forgot-passphrase" (click)="startRecovery()">{{"FAIR_CODE.FORGOT_PASSPHRASE" | translate}}</p>
      </div>
      <mat-dialog-actions class="btn-uppercase align-right">
        <button mat-button color="primary" type="button" [disabled]="fairCodeControl.disabled" (click)="cancel()"
                id="btn-abort-faircode">
          {{'COMMON.BUTTONS.CANCEL' | translate}}
        </button>
        <button mat-button color="primary" type="submit" [disabled]="fairCodeControl.disabled" id="btn-submit-faircode">
          {{'COMMON.BUTTONS.SUBMIT' | translate}}
        </button>
      </mat-dialog-actions>
    </form>
  </ng-container>
</ng-container>
<ng-template #updateForm>
  <h2 mat-dialog-title>{{'FAIR_CODE.UPDATE_PASSPHRASE' | translate}}</h2>
  <form [formGroup]="updatePassphraseForm" (ngSubmit)="updatePassphrase()" autocomplete="off">
    <div mat-dialog-content>
      <p [innerHtml]="'FAIR_CODE.PASSPHRASE_HINT_' + (data.change ? 'CHANGE' : 'UPDATE') | translate"></p>
      <div class="fair-code-strength-help">{{'FAIR_CODE.PASSPHRASE_CONSTRAINTS.STRENGTH' | translate}}</div>
      <ul class="fair-code-strength" [ngClass]="{valid: updatePassphraseForm.get('passphrase').valid}">
        <li [class]="updatePassphraseForm.getError('hasLowerCase', ['passphrase']) ? 'true':'false'">
          {{'FAIR_CODE.PASSPHRASE_CONSTRAINTS.LOWER_CASE' | translate}}
        </li>
        <li [class]="updatePassphraseForm.getError('hasUpperCase', ['passphrase']) ? 'true':'false'">
          {{'FAIR_CODE.PASSPHRASE_CONSTRAINTS.UPPER_CASE' | translate}}
        </li>
        <li [class]="updatePassphraseForm.getError('hasNumbers', ['passphrase']) ? 'true':'false'">
          {{'FAIR_CODE.PASSPHRASE_CONSTRAINTS.NUMBER' | translate}}
        </li>
        <li [class]="updatePassphraseForm.getError('hasNonAlphas', ['passphrase']) ? 'true':'false'">
          {{'FAIR_CODE.PASSPHRASE_CONSTRAINTS.SPECIAL_CHAR' | translate}}
        </li>
        <li [class]="updatePassphraseForm.getError('hasLength', ['passphrase']) ? 'true':'false'">
          {{'FAIR_CODE.PASSPHRASE_CONSTRAINTS.LENGTH' | translate}}
        </li>
      </ul>
      <input hidden name="fs-organisation-alias" [value]="data.alias"/>
      <mat-form-field appearance="fill">
        <mat-label>{{'FAIR_CODE.NEW_PASSPHRASE' | translate}}</mat-label>
        <input matInput type="password" formControlName="passphrase" autocomplete="new-password"
               [id]="'fs-fair-code-' + data.id" [name]="'fs-fair-code-' + data.id" cdkFocusInitial>
        <mat-error *ngIf="updatePassphraseForm.hasError('required', ['passphrase'])">
          {{"COMMON.ERRORS.REQUIRED" | translate}}
        </mat-error>
        <mat-error *ngIf="updatePassphraseForm.hasError('fairCodeStrength', ['passphrase'])">
          {{'FAIR_CODE.ERRORS.FAIR_CODE_STRENGTH' | translate}}
        </mat-error>
        <mat-error *ngIf="updatePassphraseForm.hasError('notChanged', ['passphrase'])">
          {{'FAIR_CODE.ERRORS.NOT_CHANGED' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{'FAIR_CODE.NEW_PASSPHRASE_CONFIRM' | translate}}</mat-label>
        <input matInput type="password" autocomplete="new-password" [id]="'fs-fair-code-new-' + data.random"
               [name]="'fs-fair-code-new-' + data.random" formControlName="passphraseConfirmation">
        <mat-error *ngIf="updatePassphraseForm.hasError('required', ['passphraseConfirmation'])">
          {{"COMMON.ERRORS.REQUIRED" | translate}}
        </mat-error>
        <mat-error *ngIf="updatePassphraseForm.hasError('notMatching', ['passphraseConfirmation'])">
          {{"FAIR_CODE.ERRORS.NOT_MATCHING" | translate}}
        </mat-error>
      </mat-form-field>
      <p class="mat-text-warn" *ngIf="updatePassphraseForm.hasError('updateFailed')">
        {{'FAIR_CODE.ERRORS.UPDATE' | translate}}</p>
    </div>
    <mat-dialog-actions class="btn-uppercase align-right">
      <button mat-button type="button" color="primary" [disabled]="updatePassphraseForm.disabled" (click)="cancel()">
        {{'COMMON.BUTTONS.CANCEL' | translate}}
      </button>
      <button mat-button type="submit" color="primary" [disabled]="updatePassphraseForm.disabled">
        {{'COMMON.BUTTONS.SUBMIT' | translate}}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #recovery>
  <h2 mat-dialog-title>{{'FAIR_CODE.RECOVERY' | translate}}</h2>
  <form [formGroup]="recoveryForm" (ngSubmit)="recover()" autocomplete="off">
    <p>{{'FAIR_CODE.RECOVERY_HINT' | translate}}</p>
    <div mat-dialog-content>
      <mat-form-field appearance="fill">
        <mat-label>{{'FAIR_CODE.MASTER_PASSPHRASE' | translate}}</mat-label>
        <input matInput type="password" formControlName="masterPassphrase" autocomplete="off" cdkFocusInitial>
        <mat-error *ngIf="recoveryForm.hasError('required', ['masterPassphrase'])">
          {{"COMMON.ERRORS.REQUIRED" | translate}}
        </mat-error>
        <mat-error *ngIf="recoveryForm.hasError('wrongPassphrase', ['masterPassphrase'])">
          {{"FAIR_CODE.ERRORS.WRONG_MASTER_PASSPHRASE" | translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-dialog-actions class="btn-uppercase align-right">
      <button mat-button type="button" color="primary" [disabled]="recoveryForm.disabled" (click)="cancelRecovery()">
        {{'COMMON.BUTTONS.CANCEL' | translate}}
      </button>
      <button mat-button type="submit" color="primary" [disabled]="recoveryForm.disabled">
        {{'COMMON.BUTTONS.SUBMIT' | translate}}
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
