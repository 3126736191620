import { Injectable } from '@angular/core';
import { RcHttpClient, RcHttpClientConfig, RightConsents } from '@fairandsmart/consents';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { OrganisationOfferProduct } from '@fairandsmart/types';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  catchError, filter, map, mergeMap,
} from 'rxjs/operators';
import { from, throwError } from 'rxjs';
import { ClientConfigDto, getClientConfig } from '@fairandsmart/consents/system';
import { ConsentManagerDownComponent } from '@Common/consent-manager-down/consent-manager-down.component';
import { environment } from '@Env';
import { AlertService } from './alert.service';
import { OrgaService } from './orga.service';

@Injectable({
  providedIn: 'root',
})
export class RightConsentsService {
  get available(): boolean {
    return Boolean(this.config);
  }

  config: ClientConfigDto;

  constructor(
    private dialog: MatDialog,
    private orgaService: OrgaService,
    private router: Router,
    private alertService: AlertService,
    private http: HttpClient,
  ) {
  }

  async init(route?: ActivatedRouteSnapshot) {
    if (this.orgaService.hasOfferForProduct(OrganisationOfferProduct.CM)) {
      if (RightConsents.initialized) { return true; }
      try {
        const location = await this.orgaService.getConsentManagerLocation().toPromise();
        RightConsents.init({
          apiRoot: location,
          httpClient: this.createHttpClient(),
          catalogRoot: environment.catalogUrl,
        });
        this.config = await getClientConfig().toPromise();
        return true;
      } catch (error) {
        this.alertService.error('RIGHT_CONSENTS.SERVICE_DOWN.ERROR');
        return this.serviceDown(route);
      }
    }
    return false;
  }

  async serviceDown(route?: ActivatedRouteSnapshot) {
    const routeData = route?.data;
    if (routeData?.enforceConsentManagerAvailability) {
      const retry = await this.dialog.open(ConsentManagerDownComponent).afterClosed().toPromise();
      if (retry) {
        try {
          const location = await this.orgaService.getConsentManagerLocation().toPromise();
          RightConsents.init({
            apiRoot: location,
            httpClient: this.createHttpClient(),
            catalogRoot: environment.catalogUrl,
          });
          return true;
        } catch (err) {
          return this.serviceDown();
        }
      } else {
        await this.router.navigateByUrl(this.orgaService.baseUrl);
        return false;
      }
    } else {
      return true;
    }
  }

  purge() {
    RightConsents.reset();
  }

  createHttpClient(): RcHttpClient {
    return <T>(config: RcHttpClientConfig) => {
      let req: HttpRequest<T>;
      if (config.method === 'POST' || config.method === 'PUT') {
        req = new HttpRequest<T>(config.method, config.url, config.body, {
          responseType: config.responseType,
          headers: new HttpHeaders(config.headers),
          params: new HttpParams({ fromObject: config.params }),
        });
      } else if (config.method === 'DELETE' || config.method === 'GET') {
        req = new HttpRequest<T>(config.method, config.url, {
          responseType: config.responseType as any,
          headers: new HttpHeaders(config.headers),
          params: new HttpParams({ fromObject: config.params }),
        });
      }
      return this.handleRequest<T>(req, config);
    };
  }

  /**
   * This handles a request to the consent manager. If there is an error without the proper CM_API_ERROR header from
   * the consent-manager-back, the "Retry" popup appears. The request will be retried over if the user chooses to.
   * If not, the error is thrown, the RightConsents lib is reset, and the serviceDown function handles the redirection
   */
  handleRequest<T>(req: HttpRequest<T>, config: RcHttpClientConfig, attemptsFailed = 0) {
    return this.http.request<T>(req).pipe(
      filter((ev) => ev.type === HttpEventType.Response),
      map((res: HttpResponse<T>) => {
        if (config.options?.data?.extractResponseHeaders === true) {
          return {
            body: res.body,
            headers: res.headers,
          };
        }
        return res.body;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !error.headers.has('CM_API_ERROR')) {
          return from(this.serviceDown()).pipe(
            mergeMap((retry) => {
              if (retry && attemptsFailed < 3) { return this.handleRequest(req, config, attemptsFailed + 1); }
              RightConsents.reset();
              return throwError(error);
            }),
          );
        }
        return throwError(error);
      }),
    );
  }
}
