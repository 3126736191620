import { NgModule } from '@angular/core';
import { FsOrgBaseModule } from '@Common/base.module';
import { HomePageComponent } from './home-page/home-page.component';
import { CompleteProfileDialogComponent } from './complete-profile-dialog/complete-profile-dialog.component';

@NgModule({
  declarations: [
    HomePageComponent,
    CompleteProfileDialogComponent,
  ],
  imports: [
    FsOrgBaseModule,
  ],
})
export class HomeModule {
}
