<h2 mat-dialog-title>{{data.title | translate:data.titleParams }}</h2>
<mat-dialog-content [innerHTML]="data.content | translate:data.contentParams"></mat-dialog-content>
<mat-dialog-actions class="btn-uppercase align-right">
  <button mat-button color="primary" *ngIf="!data.hideCancel" [mat-dialog-close]="false">
    {{(data.cancel || 'COMMON.BUTTONS.CANCEL') | translate}}
  </button>
  <button mat-button color="primary" [mat-dialog-close]="true">
    {{(data.confirm || 'COMMON.BUTTONS.CONFIRM') | translate}}
  </button>
</mat-dialog-actions>
