<fs-base-header></fs-base-header>
<div class="content">
  <mat-card>
    <ng-container *ngIf="!invalidProfileType">
      <mat-card-title>
        {{'HOME.WELCOME_' + (profile.username == null ? 'NO_USERNAME' : 'USER')| translate:{username: profile.username} }}
      </mat-card-title>
      <mat-card-subtitle *ngIf="CUSTOMIZATION.allowOrganisationCreation">
        {{'HOME.WELCOME' | translate:{appName: CUSTOMIZATION.appName} }}
      </mat-card-subtitle>
      <ng-container *ngIf="organisations != null">
        <ng-container *ngIf="organisations.length > 0; else noOrganisation">
          <mat-card-content>

            <ng-container *ngIf="profile.profile.status !== 'ACTIVE'">
              <p class="warning">
                <span>{{'HOME.PROFILE_NOT_ACTIVE' | translate}}</span>
                <button mat-raised-button (click)="completeProfile()">{{'HOME.COMPLETE_PROFILE' | translate}}</button>
              </p>
            </ng-container>

            <mat-form-field *ngIf="organisations.length > 4" appearance="outline">
              <mat-label>{{'HOME.FILTER_ORGANISATIONS' | translate}}</mat-label>
              <mat-icon matPrefix>search</mat-icon>
              <input matInput [formControl]="filter" type="text"/>
            </mat-form-field>
            <mat-nav-list>
              <ng-container *ngFor="let status of STATUSES">
                <ng-container *ngIf="organisationsByStatus[status]?.length > 0">
                  <h3 mat-subheader>{{'HOME.ORGANISATIONS.' + status | translate}}</h3>
                  <mat-list-item *ngFor="let organisation of organisationsByStatus[status]"
                                 [ngClass]="organisation.status"
                                 [disableRipple]="organisation.status === STATUS.BLOCKED"
                                 [matTooltip]="organisation.status === STATUS.PENDING ? ('HOME.PENDING' | translate) : null"
                                 (click)="selectOrganisation(organisation)">
                    <img alt="organisation logo" matListAvatar fsAvatar [round]="false" [organisationId]="organisation.id" [size]="40"/>
                    <h3 matLine>{{organisation.name}}</h3>
                    <p *ngIf="showDetails" matLine>
                      {{'HOME.ORGANISATIONS.CREATION_DATE' | translate:{date: (organisation.creationDate | date)} }}
                      | {{organisation.alias}}</p>
                    <mat-icon *ngIf="organisation.status !== STATUS.BLOCKED" matListIcon>chevron_right</mat-icon>
                  </mat-list-item>
                </ng-container>
              </ng-container>
            </mat-nav-list>
          </mat-card-content>
          <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-button (click)="showDetails = !showDetails">
              <mat-icon>info_outline</mat-icon>
              <span>{{'HOME.BUTTONS.' + (showDetails ? 'HIDE_DETAILS' : 'SHOW_DETAILS') | translate}}</span>
            </button>
            <button mat-flat-button color="primary" [routerLink]="['/create']">
              {{'HOME.BUTTONS.ADD_ORGANISATION' | translate}}
            </button>
          </mat-card-actions>
        </ng-container>
        <ng-template #noOrganisation>
          <mat-card-content class="no-organisation-content"
                            *ngIf="CUSTOMIZATION.allowOrganisationCreation; else invitationOnly">
            <p>{{'HOME.ORGANISATIONS.NONE' | translate:{appName: CUSTOMIZATION.appName} }}</p>
            <button mat-flat-button color="primary" id="fs-create-organisation" [routerLink]="['/create']">
              {{'HOME.BUTTONS.CREATE_ORGANISATION' | translate}}
            </button>
            <span class="invited-help" [matTooltip]="'HOME.INVITE_LINK' | translate">
              {{'HOME.INVITED' | translate}}
            </span>
          </mat-card-content>
        </ng-template>
        <ng-template #invitationOnly>
          <mat-card-content class="no-organisation-content">
            <p><strong>{{'HOME.INVITED' | translate}}</strong></p>
            <p>{{'HOME.INVITE_LINK' | translate}}</p>
          </mat-card-content>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="invites?.length">
        <div class="invites">
          <h3>{{'HOME.INVITES' | translate}}</h3>
          <div class="invite" *ngFor="let invite of invites">
            <ng-container *ngIf="(invite.reference | referenceToName | async) as name">
              <div class="orgaName">{{name}}</div>
              <div class="invite-actions">
                <button mat-mini-fab color="primary" (click)="acceptInvite(invite, name)"><mat-icon>check</mat-icon></button>
                <button mat-icon-button (click)="rejectInvite(invite)"><mat-icon>close</mat-icon></button>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="invalidProfileType">
      <mat-card-content>
        <div class="invalid-profile-type">
          <mat-icon class="mat-icon-2x">mood_bad</mat-icon>
          <p [innerHtml]="'HOME.INVALID_PROFILE_TYPE' | translate:{appName: CUSTOMIZATION.appName} "></p>
          <button mat-flat-button color="warn" (click)="logout()">
            {{'COMMON.BUTTONS.LOGOUT' | translate}}
          </button>
        </div>
      </mat-card-content>
    </ng-container>
  </mat-card>
</div>
