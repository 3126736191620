import { Injectable } from '@angular/core';
import { FsConnectionService } from '@fairandsmart/angular';
import { EntityCollection, FsConnection, FsConnectionFilter } from '@fairandsmart/types';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrgaService } from './orga.service';

@Injectable({ providedIn: 'root' })
export class ConnectionService {
  constructor(
    private orgaService: OrgaService,
    private fsConnectionService: FsConnectionService,
  ) {
  }

  get(id: string): Observable<FsConnection> {
    return this.fsConnectionService.get(id);
  }

  count(filter: FsConnectionFilter): Observable<number> {
    const clone: FsConnectionFilter = _.cloneDeep(filter);
    clone.limit = 0;
    return this.fsConnectionService.search(clone).pipe(map((response) => response.size));
  }

  search(filter: FsConnectionFilter): Observable<EntityCollection<FsConnection>> {
    return this.fsConnectionService.search(filter);
  }
}
